import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import {
  deleteCashDrawer,
  setCashDrawer,
  useUI,
  useUiDispatch,
} from "../../../context/UIContext";

const CashDrawerButton = ({ onClick, device }) => {
  console.log(device);
  if (device) {
    return (
      <Button
        variant="text"
        onClick={onClick}
        startIcon={
          <span className="material-symbols-outlined">contactless</span>
        }
      >
        Cash Drawer Connected
      </Button>
    );
  }
  return (
    <Button
      variant="text"
      onClick={onClick}
      startIcon={
        <span className="material-symbols-outlined">contactless_off</span>
      }
    >
      Cash Drawer Disconnected
    </Button>
  );
};

export let SerialDevicesDialogButton = function (props) {
  const [open, setOpen] = useState(false);
  const { cashDrawer } = useUI();
  const dispatch = useUiDispatch();

  const openDialog = async () => {
    setOpen(true);
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    navigator.serial.getPorts().then((ports) => {
      if (ports.length === 1 && cashDrawer == null) {
        onDeviceConnected({ target: ports[0] });
      }
    });

    navigator.serial.addEventListener("connect", (event) =>
      onDeviceConnected(event)
    );
    navigator.serial.addEventListener("disconnect", () =>
      deleteCashDrawer(dispatch)
    );
  }, []);

  const handleCloseClick = () => {
    setOpen(false);
  };

  const requestDevices = async () => {
    try {
      let device = await navigator.serial.requestPort({
        filters: [{ usbProductId: 8963, usbVendorId: 1659 }],
      });
      // connect
      setCashDrawer(device, dispatch);
    } catch (e) {
      // ignore if user cancels request
    }
  };

  const onDeviceConnected = (event) => {
    console.log("USB Connected");
    console.log(event);
    setCashDrawer(event.target, dispatch);
  };

  const handleForgetClick = async (device) => {
    try {
      await device.forget();
      deleteCashDrawer(dispatch);
    } catch (e) {}
  };

  return (
    <>
      <CashDrawerButton
        onClick={() => openDialog(true)}
        device={cashDrawer}
      ></CashDrawerButton>
      <Dialog maxWidth="xs" fullWidth={true} open={open}>
        <DialogTitle>Cash Drawer Settings</DialogTitle>

        <Grid
          mb={2}
          ml={4}
          container
          spacing={3}
          width="100%"
          flexDirection="column"
          alignItems="center"
        >
          <Grid width={"100%"} item mb={2} container spacing={3}>
            {cashDrawer ? (
              <>
                <Grid
                  item
                  container
                  flexDirection="row"
                  alignItems="center"
                  spacing={3}
                  sx={{
                    boxShadow:
                      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
                  }}
                >
                  <Grid item>
                    <Typography variant="p">Device Connected</Typography>
                  </Grid>
                  <Grid item>
                    <Button onClick={() => handleForgetClick(cashDrawer)}>
                      Forget
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid item container flexDirection="column" alignItems="center">
                <Grid item>
                  <Typography>No Device Connected</Typography>
                </Grid>
                <Grid item>
                  <Button onClick={requestDevices}>Add New Device</Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <DialogActions>
          <Button onClick={handleCloseClick}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
