import { CategoriesProvider } from "./CategoriesContext";
import { OrderProvider } from "./Order/OrderContext";
import { SnackbarMessageProvider } from "./SnackbarMessageContext";
import { UIProvider } from "./UIContext";

const combineProviders = (providers) =>
  providers.reduce(
    (AccumulatedProviders, [Provider, props = {}]) =>
      ({ children }) =>
        (
          <AccumulatedProviders>
            <Provider {...props}>
              <>{children}</>
            </Provider>
          </AccumulatedProviders>
        ),
    ({ children }) => <>{children}</>
  );

export const PointOfSaleProviders = combineProviders([
  [SnackbarMessageProvider],
  [CategoriesProvider],
  [OrderProvider],
  [UIProvider],
]);
