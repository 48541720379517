import { Typography, Grid, Button, Box, FormControlLabel, Checkbox } from "@mui/material";
import PropTypes from "prop-types";
import { useMsal } from "@azure/msal-react";
import CategoryCard from "./CategoryCard";
import { useEffect, useState } from "react";
import {
  loadCategoriesAsync,
  selectCategoryAsync,
  useCategories,
  useCategoriesDispatch,
} from "../../../context/CategoriesContext";
import ProductCard from "./ProductCard";

export default function BrowseTabPanel(props) {
  const { children, value, index, ...other } = props;
  var { instance } = useMsal();
  const dispatch = useCategoriesDispatch();
  const categories = useCategories();

  useEffect(() => {
    async function getInitialCategories() {
      await loadCategoriesAsync(instance, dispatch);
    }
    getInitialCategories();
  }, [instance, dispatch]);

  const backButton = () =>
    categories.parentCategoryId !== 0 ? (
      <>
        <Button onClick={() => handleHomeButtonClick()}>Home</Button>
        <Button onClick={() => handleBackButtonClick()}>Back</Button>
      </>
    ) : (
      <></>
    );

  const handleHomeButtonClick = async () => {
    await loadCategoriesAsync(instance, dispatch);
  };

  const handleBackButtonClick = async () => {
    categories.history.pop();
    const newCategory = categories.history.pop();
    await selectCategoryAsync(
      newCategory.id,
      newCategory.name,
      instance,
      dispatch
    );
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ height: "650px", overflow: "auto" }}>
          <Typography variant="h6" align="center">
            {categories.categoryName}
          </Typography>
          {backButton()}
          <Grid container alignItems="center" justifyContent="center">
            {categories.categoryItems ? (
              categories.categoryItems.map((element) => {
                return (
                  <Grid key={element.id} item xs={2} m={1}>
                    <CategoryCard
                      name={element.name}
                      pictureUrl={element.pictureUrl}
                      parentCategoryId={element.parentCategoryId}
                      id={element.id}
                      hasProducts={element.hasProducts}
                    ></CategoryCard>
                  </Grid>
                );
              })
            ) : (
              <></>
            )}
          </Grid>
          <Grid container alignItems="center" justifyContent="center">
            {categories.products ? (
              categories.products.map((element) => {
                return (
                  <Grid key={element.id} item xs={3} m={1}>
                    <ProductCard {...element}></ProductCard>
                  </Grid>
                );
              })
            ) : (
              <></>
            )}
          </Grid>
        </Box>
      )}
    </div>
  );
}

BrowseTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
