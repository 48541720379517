import {
  Dialog,
  DialogTitle,
  DialogContentText,
  Grid,
  TextField,
  InputAdornment,
  DialogActions,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  useOrder,
  useOrderDispatch,
} from "../../../context/Order/OrderContext";
import { useMsal } from "@azure/msal-react";
import {
  addItem,
  getProductByPluAsync,
} from "../../../context/Order/orderActions";

export const MiscDialogButton = (props) => {
  const [open, setOpen] = useState(false);
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState(1);
  const { instance } = useMsal();
  const orderDispatch = useOrderDispatch();
  const order = useOrder();

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleAddMiscButtonClickAsync = async (name) => {
    setOpen(false);
    await addMiscItemAsync(name);
  };

  const addMiscItemAsync = async (name) => {
    var response = await getProductByPluAsync(name, instance, true);
    if (response.ok) {
      var data = await response.json();
      console.log(data);
      addItem(
        { name, qty: Number(quantity), ...data[0], price: Number(price) },
        order.refundMode,
        orderDispatch
      );
    }
    setPrice("");
    setQuantity(1);
  };

  const handleCancelClick = () => {
    setPrice("");
    setQuantity(1);
    setOpen(false);
  };

  return (
    <>
      <Button variant="outlined" size="small" onClick={() => setOpen(true)}>
        Misc
      </Button>
      <Dialog open={open}>
        <DialogTitle>Miscellaneous Items</DialogTitle>
        <DialogContentText p={3}>
          Enter the value and quantity of the item and and press the
          corresponding button to add the sale.
        </DialogContentText>
        <Grid
          mb={2}
          pl={3}
          container
          spacing={3}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <TextField
              sx={{ width: "10ch" }}
              label="Price"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">£</InputAdornment>
                ),
              }}
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              size="small"
            ></TextField>
          </Grid>
          <Grid item>
            <TextField
              sx={{ width: "10ch" }}
              label="Quantity"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              size="small"
            ></TextField>
          </Grid>
        </Grid>
        <DialogActions>
          <Button color="error" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button onClick={() => handleAddMiscButtonClickAsync("Delivery")}>
            Delivery
          </Button>
          <Button onClick={() => handleAddMiscButtonClickAsync("Misc")}>
            Misc
          </Button>
          <Button onClick={() => handleAddMiscButtonClickAsync("Sundries")}>
            Sundries
          </Button>
          <Button onClick={() => handleAddMiscButtonClickAsync("Silks")}>
            Silks
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
