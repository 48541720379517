import {
  Dialog,
  DialogTitle,
  DialogContentText,
  Grid,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { layawayOrder } from "../../../context/Order/orderActions";
import { useMsal } from "@azure/msal-react";
import {
  useOrder,
  useOrderDispatch,
} from "../../../context/Order/OrderContext";

export default function LayawayDialogButton() {
  const [open, setOpen] = useState(false);
  const { instance } = useMsal();
  const order = useOrder();
  const dispatch = useOrderDispatch();

  const handleConfirmClick = async () => {
    await layawayOrder(order, instance, dispatch);
    setOpen(false);
  };

  const handleCancelClick = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Layaway
      </Button>

      <Dialog open={open} p={5}>
        <DialogTitle textAlign="center">Layaway</DialogTitle>
        <DialogContentText textAlign="center" m={3}>
          Are you sure you want to save the order for later?
        </DialogContentText>
        <DialogActions>
          <Button onClick={handleCancelClick} color="error">
            Cancel
          </Button>
          <Button onClick={handleConfirmClick} color="success">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
