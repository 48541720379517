import * as React from 'react';
import {Tabs, Tab, Box, Card, CardContent} from '@mui/material';
import ItemsTabPanel from './ItemsTab/ItemsTabPanel';
import BrowseTabPanel from './BrowseTab/BrowseTabPanel';
import SearchTabPanel from './SearchTab/SearchTabPanel';



function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export default function MainTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box mt={2} sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          <Tab label="Items" {...a11yProps(0)} />
          <Tab label="Browse" {...a11yProps(1)} />
          <Tab label="Search" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <Card>
        <CardContent sx={{backgroundColor:"#f6f6f6"}}>
      <ItemsTabPanel value={value} index={0}>
      </ItemsTabPanel>
      <BrowseTabPanel value={value} index={1}>
      </BrowseTabPanel>
      <SearchTabPanel value={value} index={2}>
      </SearchTabPanel>
        </CardContent>
      </Card>
      
    </Box>
  );
}