import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Grid,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import {
  useOrder,
  useOrderDispatch,
} from "../../../context/Order/OrderContext";
import DiscountTableCell from "./DiscountTableCell";
import { useEffect, useState } from "react";
import { MiscDialogButton } from "../Dialogs/MiscDialogButton";
import {
  changeItemQty,
  deleteAllItems,
  deleteItem,
  deleteLastItem,
  resetOrder,
  setRefundMode,
} from "../../../context/Order/orderActions";
import RefundDialogButton from "../Dialogs/RefundDialogButton";
import { useMsal } from "@azure/msal-react";

const QtyCell = (props) => {
  const [qty, setQty] = useState(props.qty);

  const dispatch = useOrderDispatch();

  useEffect(() => {
    setQty(props.qty);
  }, [props.itemId, props.qty]);

  const handleQtyOnKeyUp = (event) => {
    if (event.key === "Enter") {
      handleQtyOnBlur();
    }
  };

  const handleQtyOnBlur = () => {
    changeItemQty(props.itemId, qty, props.isRefund, dispatch);
  };

  return (
    <TextField
      sx={{ width: "9ch", fontSize: "12px" }}
      size="small"
      value={qty}
      onChange={(e) => {
        let newQty = Number(e.target.value);
        if (!newQty || isNaN(newQty)) {
          newQty = qty;
        }
        setQty(newQty);
      }}
      onBlur={handleQtyOnBlur}
      onKeyUp={handleQtyOnKeyUp}
    ></TextField>
  );
};

export default function ItemsTabPanel(props) {
  const { children, value, index, ...other } = props;
  const order = useOrder();
  const items = order.items;
  console.log(order);
  const dispatch = useOrderDispatch();
  const { instance } = useMsal();

  const [refundDialogOpen, setRefundDialogOpen] = useState(false);

  const handleVoidAllClick = () => {
    deleteAllItems(dispatch);
  };

  const handleVoidLastClick = () => {
    deleteLastItem(dispatch);
  };

  const handleNewOrderClick = async () => {
    await resetOrder(dispatch, instance);
  };

  return (
    <Box
      sx={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1, width: "100%", height: "650px" }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <ToggleButtonGroup
                    size="small"
                    value={order.refundMode ? "refund" : "order"}
                  >
                    <ToggleButton
                      value="order"
                      onClick={() => setRefundMode(false, null, dispatch)}
                    >
                      Order
                    </ToggleButton>
                    <ToggleButton
                      value="refund"
                      onClick={() => setRefundDialogOpen(true)}
                    >
                      Refund
                    </ToggleButton>
                    <RefundDialogButton
                      isOpen={refundDialogOpen}
                      onClose={() => setRefundDialogOpen(false)}
                      type="order"
                    ></RefundDialogButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleVoidLastClick()}
                  >
                    Void Last
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleVoidAllClick()}
                  >
                    Void All
                  </Button>
                </Grid>
                <Grid item>
                  <MiscDialogButton></MiscDialogButton>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={async () => await handleNewOrderClick()}
                  >
                    New Order
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TableContainer
                component={Paper}
                sx={{ width: "100%", height: "600px", overflow: "auto" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ padding: "3px 10px" }}>SKU</TableCell>
                      <TableCell sx={{ padding: "3px 3px" }}></TableCell>
                      <TableCell sx={{ padding: "3px 3px" }}></TableCell>
                      <TableCell sx={{ padding: "3px 10px" }}>Qty</TableCell>
                      <TableCell sx={{ padding: "3px 10px" }}>
                        Description
                      </TableCell>
                      <TableCell sx={{ padding: "3px 10px" }} align="center">
                        Unit Price
                      </TableCell>
                      <TableCell sx={{ padding: "3px 10px" }} align="center">
                        Discount
                      </TableCell>
                      <TableCell sx={{ padding: "3px 10px" }} align="center">
                        Net Price
                      </TableCell>
                      <TableCell sx={{ padding: "3px 10px" }} align="center">
                        Stock Level
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          {item.assortedBox ? (
                            <TableCell sx={{ padding: "3px 10px" }}>
                              <div
                                className="multi-colour-text"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span class="material-symbols-outlined">
                                  stack_star
                                </span>
                                <span>{item.sku}</span>
                              </div>
                            </TableCell>
                          ) : (
                            <TableCell>{item.sku}</TableCell>
                          )}
                          <TableCell sx={{ padding: "3px 10px" }}>
                            <Button
                              onClick={() =>
                                deleteItem(item.id, item.isRefund, dispatch)
                              }
                            >
                              Void
                            </Button>
                          </TableCell>
                          <TableCell sx={{ padding: "3px 10px" }}>
                            {order.items.some(
                              (i) => i.id === item.id && i.isRefund
                            ) ? (
                              <></>
                            ) : (
                              <RefundDialogButton
                                itemId={item.id}
                                type="item"
                              ></RefundDialogButton>
                            )}
                            {item.isRefund ? (
                              <Typography color="red">REFUND</Typography>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                          <TableCell
                            sx={{ padding: "3px 10px" }}
                            align="center"
                          >
                            <QtyCell
                              qty={item.qty}
                              isRefund={item.isRefund}
                              itemId={item.id}
                            />
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "12px", padding: "3px 10px" }}
                          >
                            {item.description}
                            {item.bulkDiscountApplied ? (
                              <div style={{ color: "red" }}>
                                {" "}
                                - BULK DISCOUNT APPLIED
                              </div>
                            ) : (
                              <></>
                            )}
                          </TableCell>
                          <TableCell
                            sx={{ padding: "3px 10px" }}
                            align="center"
                          >{`£${item.unitPrice.toFixed(2)}`}</TableCell>
                          <TableCell
                            sx={{ padding: "3px 10px" }}
                            align="center"
                          >
                            <DiscountTableCell
                              itemId={item.id}
                              discount={item.discount}
                              discountType={item.discountType}
                              isRefund={item.isRefund}
                            ></DiscountTableCell>
                          </TableCell>
                          <TableCell
                            sx={{ padding: "3px 10px" }}
                            align="center"
                          >{`£${item.netPrice.toFixed(2)}`}</TableCell>
                          <TableCell
                            sx={{ padding: "3px 10px" }}
                            align="center"
                          >
                            {item.stockLevel}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      <TableCell
                        colSpan={8}
                        sx={{ paddingLeft: 2, borderBottom: "none" }}
                      >
                        <span className="cursor-blink">&gt;</span>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          
        </Box>
      )}
    </Box>
  );
}

ItemsTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
