import {
  Dialog,
  DialogTitle,
  DialogContentText,
  Grid,
  DialogActions,
  Button,
} from "@mui/material";
import { useState } from "react";

export default function ConfirmationDialogButton({
  label,
  variant,
  size,
  confirmationText,
  onYesClick,
  color,
}) {
  const [open, setOpen] = useState(false);

  const handleConfirmClick = () => {
    if (onYesClick) {
      onYesClick();
    }
    setOpen(false);
  };

  const handleCancelClick = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        size={size ?? "normal"}
        variant={variant ?? "outlined"}
        color={color ?? "primary"}
        onClick={() => setOpen(true)}
      >
        {label}
      </Button>
      <Dialog open={open}>
        <DialogTitle>Are You Sure?</DialogTitle>
        <DialogContentText p={3}>{confirmationText}</DialogContentText>
        <Grid
          mb={2}
          pl={3}
          container
          spacing={3}
          alignItems="center"
          justifyContent="center"
        ></Grid>
        <DialogActions>
          <Button onClick={handleCancelClick} color="error">
            No
          </Button>
          <Button onClick={handleConfirmClick} color="success">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
