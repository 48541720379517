import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import SidePanel from "../SidePanel";
import {
  useOrder,
  useOrderDispatch,
} from "../../../context/Order/OrderContext";
import { forwardRef, useEffect, useState } from "react";
import { Table } from "reactstrap";
import {
  generateInvoicePdf,
  completeOrder,
  generateDeliveryNotePdf,
  generateCreditNotePdf,
  generatePickingSheetPdf,
  generateOrderConfirmationPdf,
  generatePdfOrderData,
  saveOrderChanges,
  CompleteOrder,
  printReceipt,
} from "../../../services/apiService";
import { useMsal } from "@azure/msal-react";
import { useSnackbarMessage } from "../../../context/SnackbarMessageContext";
import {
  loadCategoriesAsync,
  useCategoriesDispatch,
} from "../../../context/CategoriesContext";
import ConfirmationDialogButton from "./ConfirmationDialog";
import { format, set } from "date-fns";
import {
  addPayment,
  removePayment,
  resetOrder,
} from "../../../context/Order/orderActions";
import { paymentTypes } from "../../../constants/paymentTypes";
import { setFocus, useUI, useUiDispatch } from "../../../context/UIContext";
import { openDrawer } from "../../../services/cashDrawerService";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FinishOrderButton({ pluInputRef }) {
  const [open, setOpen] = useState(false);
  const { triggerMessage } = useSnackbarMessage();

  const { instance } = useMsal();
  const order = useOrder();
  const orderDispatch = useOrderDispatch();
  const uiDispatch = useUiDispatch();
  const categoriesDispatch = useCategoriesDispatch();
  const [paymentAmount, setPaymentAmount] = useState(order.total.toFixed(2));
  const { cashDrawer } = useUI();
  const [disableFinishButton, setDisableFinishButton] = useState(false);

  useEffect(() => {
    setPaymentAmount(order.total.toFixed(2));
  }, [order.total]);

  const handleClickOpen = () => {
    setOpen(true);
    setFocus(uiDispatch);
  };

  let paymentDiff =
    order.total -
    order.payments.reduce((a, c) => a + Number(c.paymentAmount), 0);

  const handleClose = () => {
    setOpen(false);
    setFocus(uiDispatch);
  };

  const handleFinishOrderClick = async () => {
    setDisableFinishButton(true);
    await CompleteOrder(order.id, instance);
    setOpen(false);
    resetOrder(orderDispatch);
    loadCategoriesAsync(instance, categoriesDispatch);
    triggerMessage("Order Complete", "success");
    console.log(pluInputRef);
    console.log(pluInputRef.current);
    console.log(pluInputRef.current.focus());
    setDisableFinishButton(false);
    pluInputRef.current.focus();
    if (cashDrawer) {
      openDrawer(cashDrawer);
    }
  };

  const handleSaveChangesClick = async () => {
    await saveOrderChanges(order, instance);
    setOpen(false);
    resetOrder(orderDispatch);
    loadCategoriesAsync(instance, categoriesDispatch);
    triggerMessage("Order Changes Saved", "success");
  };

  const handleVoidOrderClick = async () => {
    setOpen(false);
    resetOrder(orderDispatch);
    loadCategoriesAsync(instance, categoriesDispatch);
    triggerMessage("Order Cancelled", "info");
    pluInputRef.current.focus();
  };

  const handlePaymentOptionClick = (paymentOption) => {
    addPayment(paymentAmount, paymentOption, orderDispatch);
  };

  const handleRemovePayment = (paymentIndex) => {
    removePayment(paymentIndex, orderDispatch);
  };

  return (
    <div>
      <Button variant="contained" color="success" onClick={handleClickOpen}>
        Finish Order
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }} color="success">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Complete Order
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Paper>
            <Box
              sx={{
                width: "65%",
                float: "left",
                padding: "5px",
              }}
            >
              <Grid container item xs={12}>
                <Box sx={{ width: "100%" }}>
                  <Card sx={{ backgroundColor: "#f6f6f6" }}>
                    <CardContent>
                      <Box component="form" noValidate autoComplete="off">
                        <Grid
                          spacing={1}
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item>
                            <TextField
                              sx={{ backgroundColor: "#fff" }}
                              value={paymentAmount}
                              onChange={(e) => setPaymentAmount(e.target.value)}
                              autoFocus
                              margin="normal"
                              label="Amount"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    £
                                  </InputAdornment>
                                ),
                              }}
                            ></TextField>
                          </Grid>
                        </Grid>
                      </Box>
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          handlePaymentOptionClick(paymentTypes.cash)
                        }
                      >
                        Cash
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          handlePaymentOptionClick(paymentTypes.creditCard)
                        }
                      >
                        Credit Card
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          handlePaymentOptionClick(paymentTypes.cheque)
                        }
                      >
                        Cheque
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          handlePaymentOptionClick(paymentTypes.bankTransfer)
                        }
                      >
                        Bank Transfer
                      </Button>
                      {order.account?.account ? (
                        <>
                          {" "}
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={!order.account?.account.enabled}
                            onClick={() =>
                              handlePaymentOptionClick(paymentTypes.account)
                            }
                          >
                            Account
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                    </CardActions>
                  </Card>
                </Box>
              </Grid>
              <Grid item>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Processed By</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order.payments.map((p, i) => (
                      <TableRow key={i}>
                        <TableCell></TableCell>
                        <TableCell>
                          £{Number(p.paymentAmount).toFixed(2)}
                        </TableCell>
                        <TableCell>{p.paymentType}</TableCell>
                        <TableCell>
                          {format(p.paymentDate, "dd/MM/yyyy")}
                        </TableCell>
                        <TableCell>
                          {instance.getActiveAccount().name ??
                            instance.getActiveAccount().username}
                        </TableCell>
                        <TableCell>
                          {" "}
                          <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => handleRemovePayment(i)}
                            aria-label="close"
                          >
                            <CloseIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
              <Grid item container justifyContent="end" alignItems="flex-end">
                <Grid item xs={3}>
                  <Typography>
                    Total Payments: £
                    {order.payments
                      .reduce((a, c) => a + Number(c.paymentAmount), 0)
                      .toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>
                    {paymentDiff < 0 ? (
                      <>Change Due: £{paymentDiff.toFixed(2)}</>
                    ) : (
                      <>Amount Outstanding: £{paymentDiff.toFixed(2)}</>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                width: "25%",
                padding: "5px",
                float: "left",
              }}
            >
              <SidePanel noButtons></SidePanel>
            </Box>
          </Paper>
        </DialogContent>

        <DialogActions>
          <Grid container justifyContent="center" spacing={1}>
            <Grid item>
              <Button variant="outlined" color="error" onClick={handleClose}>
                Close
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  let orderData = generatePdfOrderData(order);
                  await generateInvoicePdf(orderData, instance);
                }}
              >
                Print Invoice
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  let orderData = generatePdfOrderData(order);
                  await generateDeliveryNotePdf(orderData, instance);
                }}
              >
                Delivery Note
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  let orderData = generatePdfOrderData(order);
                  await generateCreditNotePdf(orderData, instance);
                }}
              >
                Credit Note
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  let orderData = generatePdfOrderData(order);
                  await generateOrderConfirmationPdf(orderData, instance);
                }}
              >
                Order Confirmation
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  let orderData = generatePdfOrderData(order);
                  await generatePickingSheetPdf(orderData, instance);
                }}
              >
                Picking Sheet
              </Button>
            </Grid>
            <Grid item>
              <ConfirmationDialogButton
                variant="contained"
                onYesClick={handleVoidOrderClick}
                color="primary"
                label=" Void Order"
                confirmationText="Are you sure you want to void the order?"
              ></ConfirmationDialogButton>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={handleFinishOrderClick}
                color="error"
                disabled={disableFinishButton}
              >
                Finish
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  let orderData = generatePdfOrderData(order);
                  printReceipt(orderData, instance);
                  if (cashDrawer) {
                    openDrawer(cashDrawer);
                  }
                }}
              >
                Receipt
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
