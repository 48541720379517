import {
  Box,
  Button,
  Card,
  CardContent,
  DialogContentText,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useOrder, useOrderDispatch } from "../../context/Order/OrderContext";
import { discountTypes } from "../../constants/discountTypes";
import { useState } from "react";
import PercentIcon from "@mui/icons-material/Percent";
import DiscountDialogButton from "./Dialogs/DiscountDialogButton";
import FinishOrderButton from "./Dialogs/FinishOrderButton";
import ConfirmationDialogButton from "./Dialogs/ConfirmationDialog";
import "material-symbols";
import {
  loadCategoriesAsync,
  useCategoriesDispatch,
} from "../../context/CategoriesContext";
import { useSnackbarMessage } from "../../context/SnackbarMessageContext";
import { useMsal } from "@azure/msal-react";
import {
  changeOrderDiscount,
  resetOrder,
  updateVatPercentage,
} from "../../context/Order/orderActions";
import AccountsDialogButton from "./Dialogs/AccountsDialogButton";
import OrdersDialogButton from "./Dialogs/OrdersDialogButton";
import LayawayDialogButton from "./Dialogs/LayawayDialogButton";
import { SerialDevicesDialogButton } from "./Dialogs/SerialDevicesDialogButton";
import { useUI } from "../../context/UIContext";
import { openDrawer } from "../../services/cashDrawerService";

export default function SidePanel(props) {
  const {
    items,
    vatPercentage,
    orderDiscount,
    orderDiscountType,
    discountAmount,
    netTotal,
    total,
    totalItems,
    vatAmount,
  } = useOrder();
  const { instance } = useMsal();
  const orderDispatch = useOrderDispatch();
  const order = useOrder();
  const categoriesDispatch = useCategoriesDispatch();
  const { triggerMessage } = useSnackbarMessage();
  const [editVAT, setEditVAT] = useState(false);
  const { cashDrawer } = useUI();

  const handleVatPercentageChange = (e) => {
    updateVatPercentage(e.target.value, orderDispatch);
  };

  const handleRemoveDiscount = () => {
    changeOrderDiscount(0, discountTypes.pounds, orderDispatch);
  };

  const handleVoidOrderClick = async () => {
    resetOrder(orderDispatch);
    await loadCategoriesAsync(instance, categoriesDispatch);
    triggerMessage("Order Cancelled", "info");
  };

  const handleNoSaleClick = async () => {
    await openDrawer(cashDrawer);
  };

  return (
    <>
      <Card sx={props.noButtons ? {} : { height: "875px" }}>
        <CardContent sx={{ height: "100%" }}>
          <Grid container>
            {props.noButtons ? (
              <></>
            ) : (
              <Grid container alignItems="center" spacing={2} display="flex">
                <Grid
                  alignItems="center"
                  xs
                  display="flex"
                  justifyContent="flex-end"
                >
                  <AccountsDialogButton></AccountsDialogButton>
                </Grid>
                <Grid
                  alignItems="center"
                  xs
                  display="flex"
                  justifyContent="flex-end"
                >
                  <OrdersDialogButton></OrdersDialogButton>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container>
            {order.orderNumber ? (
              <>
                <Grid
                  xs={4}
                  alignItems="center"
                  display="flex"
                  justifyContent="center"
                >
                  <Typography variant="body1">
                    Order: {order.orderNumber}
                  </Typography>
                </Grid>
              </>
            ) : (
              <></>
            )}
            {order.account ? (
              <>
                <Grid
                  alignItems="center"
                  xs={12}
                  display="flex"
                  justifyContent="center"
                >
                  <Typography variant="body1">
                    Account: {order.account.company}
                  </Typography>
                </Grid>
                {order.account.account ? (
                  <>
                    <Grid
                      alignItems="center"
                      xs={12}
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography variant="body1">
                        Owed: £{order.account.account?.amountOwed}
                      </Typography>
                    </Grid>
                    <Grid
                      alignItems="center"
                      xs={12}
                      display="flex"
                      justifyContent="center"
                    >
                      <Typography variant="body1">
                        Limit: £{order.account.account?.limit}
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                <Divider variant="middle"></Divider>
              </>
            ) : (
              <></>
            )}
          </Grid>
          <Grid container>
            <Grid
              alignItems="center"
              xs={8}
              display="flex"
              justifyContent="flex-end"
            >
              <Typography variant="body1">Scanned Items:</Typography>
            </Grid>
            <Grid
              alignItems="center"
              xs
              display="flex"
              justifyContent="flex-end"
            >
              <Typography variant="h6">{items.length}</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              alignItems="center"
              xs={8}
              display="flex"
              justifyContent="flex-end"
            >
              <Typography variant="body1">Total Items:</Typography>
            </Grid>
            <Grid
              alignItems="center"
              xs
              display="flex"
              justifyContent="flex-end"
            >
              <Typography variant="h6">{totalItems}</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              alignItems="center"
              xs={8}
              display="flex"
              justifyContent="flex-end"
            >
              {props.noButtons ? (
                <></>
              ) : editVAT ? (
                <Button color="success" onClick={() => setEditVAT(false)}>
                  Confirm
                </Button>
              ) : (
                <Button onClick={() => setEditVAT(true)} variant="text">
                  Change
                </Button>
              )}

              <Typography variant="body1">VAT Rate:</Typography>
            </Grid>
            <Grid
              alignItems="center"
              xs
              display="flex"
              justifyContent="flex-end"
            >
              {editVAT ? (
                <TextField
                  size="small"
                  sx={{ width: "10ch" }}
                  value={vatPercentage}
                  onChange={handleVatPercentageChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <PercentIcon />
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              ) : (
                <Typography variant="h6">{vatPercentage}%</Typography>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              alignItems="center"
              xs={8}
              display="flex"
              justifyContent="flex-end"
            >
              {props.noButtons ? (
                <></>
              ) : (
                <Button variant="text" onClick={handleRemoveDiscount}>
                  Remove
                </Button>
              )}

              <Typography variant="body1">Order Discount:</Typography>
            </Grid>
            <Grid
              alignItems="center"
              xs
              display="flex"
              justifyContent="flex-end"
            >
              <Typography variant="h6">
                {" "}
                {orderDiscountType === discountTypes.pounds ? <>-£</> : <></>}
                {Number(orderDiscount).toFixed(2)}
                {orderDiscountType === discountTypes.percent ? (
                  <>
                    {" "}
                    %
                    <Typography variant="h6">
                      (£{Number(discountAmount).toFixed(2)})
                    </Typography>
                  </>
                ) : (
                  <></>
                )}
              </Typography>
            </Grid>
          </Grid>
          {order.refundAmount ? (
            <Grid container>
              <Grid
                alignItems="center"
                xs={8}
                display="flex"
                justifyContent="flex-end"
              >
                <Typography variant="body1">Refund Amount:</Typography>
              </Grid>
              <Grid
                alignItems="center"
                xs
                display="flex"
                justifyContent="flex-end"
              >
                <Typography variant="h6">
                  £{order.refundAmount.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          <Grid container>
            <Grid
              alignItems="center"
              xs={8}
              display="flex"
              justifyContent="flex-end"
            >
              <Typography variant="body1">Net Total:</Typography>
            </Grid>
            <Grid
              alignItems="center"
              xs
              display="flex"
              justifyContent="flex-end"
            >
              <Typography variant="h6">£{netTotal.toFixed(2)}</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              alignItems="center"
              xs={8}
              display="flex"
              justifyContent="flex-end"
            >
              <Typography variant="body1">VAT Amount:</Typography>
            </Grid>
            <Grid
              alignItems="center"
              xs
              display="flex"
              justifyContent="flex-end"
            >
              <Typography variant="h6">£{vatAmount.toFixed(2)}</Typography>
            </Grid>
          </Grid>

          <Box
            sx={{
              padding: "10px",
              backgroundColor: "#393939",
              border: "solid 10px #808080",
              color: "#cc333f",
              margin: "50px 10px",
            }}
          >
            <Grid container>
              <Grid
                alignItems="center"
                xs
                display="flex"
                justifyContent="center"
              >
                <Typography variant="h6">£{total.toFixed(2)}</Typography>
              </Grid>
            </Grid>
          </Box>

          {props.noButtons ? (
            <></>
          ) : (
            <Grid
              sx={{ margin: "150px 0" }}
              container
              rowSpacing={4}
              columnSpacing={1}
            >
              <Grid alignItems="flex-end">
                <Button variant="outlined" onClick={handleNoSaleClick}>
                  No Sale
                </Button>
              </Grid>
              <Grid alignItems="flex-end">
                <DiscountDialogButton></DiscountDialogButton>
              </Grid>
              <Grid alignItems="flex-end">
                <LayawayDialogButton></LayawayDialogButton>
              </Grid>
              <Grid alignItems="flex-end">
                <FinishOrderButton
                  pluInputRef={props.pluInputRef}
                ></FinishOrderButton>
              </Grid>
              <Grid alignItems="flex-end">
                <SerialDevicesDialogButton />
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </>
  );
}
