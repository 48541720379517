import { CurrencyPound, PercentOutlined } from "@mui/icons-material";
import {
  Grid,
  InputAdornment,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  useOrder,
  useOrderDispatch,
} from "../../../context/Order/OrderContext";
import { discountTypes } from "../../../constants/discountTypes";
import { changeItemDiscount } from "../../../context/Order/orderActions";

export default function DiscountTableCell(props) {
  const [discountType, setDiscountType] = useState(
    props.discountType || discountTypes.percent
  );
  const [amount, setAmount] = useState(props.discount || 0);
  const [change, setChange] = useState(false);
  const {items} = useOrder();
  const itemsDispatch = useOrderDispatch();

  useEffect(() => {
    const item = items.find((i) => i.id === props.itemId);
    setAmount(item.discount);
    setDiscountType(item.discountType);
  }, [props.itemId, items]);

  const onAmountChanged = (e) => {
    setAmount(e.target.value);
  };

  const onToggleDiscountType = (newValue) => {
    setDiscountType(newValue);
  };

  const onConfirmClick = () => {
    let discount = amount;
    if (!amount) {
      setAmount(0);
      discount = 0;
    }
    changeItemDiscount(props.itemId, discount, discountType, props.isRefund, itemsDispatch);
    setChange(false);
  };

  return (
    <Grid container>
      {change ? (
        <>
          <Grid xs={12} item>
            <TextField
              sx={{ width: "12ch" }}
              InputProps={{
                endAdornment:
                  discountType === discountTypes.percent ? (
                    <InputAdornment position="end">%</InputAdornment>
                  ) : (
                    <></>
                  ),
                startAdornment:
                  discountType === discountTypes.pounds ? (
                    <InputAdornment position="start">£</InputAdornment>
                  ) : (
                    <></>
                  ),
              }}
              value={amount}
              onChange={(e) => onAmountChanged(e)}
              size="small"
            ></TextField>
          </Grid>
          <Grid xs={12} item>
            <ToggleButtonGroup
              size="small"
              exclusive={true}
              value={discountType}
            >
              <ToggleButton
                value={discountTypes.percent}
                onClick={() => onToggleDiscountType(discountTypes.percent)}
              >
                <PercentOutlined sx={{ width: "16px" }} />
              </ToggleButton>
              <ToggleButton
                value={discountTypes.pounds}
                onClick={() => onToggleDiscountType(discountTypes.pounds)}
              >
                <CurrencyPound sx={{ width: "16px" }} />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </>
      ) : (
        <Grid xs={12} item>
          {discountType === discountTypes.pounds ? <>£</> : <></>}
          {Number(amount).toFixed(2)}
          {discountType === discountTypes.percent ? <> %</> : <></>}
        </Grid>
      )}
      <Grid xs={12} item>
        {change ? (
          <Button
            variant="text"
            color="success"
            onClick={() => onConfirmClick()}
          >
            Confirm
          </Button>
        ) : (
          <Button size="small" variant="text" onClick={() => setChange(true)}>
            Change
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
