import { Alert, Snackbar } from "@mui/material";
import { createContext, useContext, useState } from "react";

export const SnackbarMessageContext = createContext(null);

export function SnackbarMessageProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Error Occurred");
  const [severity, setSeverity] = useState('error');

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const triggerMessage = (message, severity) => {
    setSeverity(severity);
    setErrorMessage(message);
    setOpen(true);
  };

  return (
    <SnackbarMessageContext.Provider value={{ triggerMessage: triggerMessage }}>
      {children}
      <Snackbar
        sx={{ width: "50%" }}
        open={open}
        autoHideDuration={700}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{ width: "100%", height: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </SnackbarMessageContext.Provider>
  );
}

export function useSnackbarMessage() {
  return useContext(SnackbarMessageContext);
}
