import {
  Dialog,
  DialogTitle,
  DialogContentText,
  Grid,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ToggleButton,
} from "@mui/material";
import { useState } from "react";
import {
  useOrder,
  useOrderDispatch,
} from "../../../context/Order/OrderContext";
import {
  setItemToRefund,
  setRefundMode,
} from "../../../context/Order/orderActions";
import { useEffect } from "react";

export default function RefundDialogButton({ itemId, type, isOpen, onClose }) {
  const reasons = {
    damagedItem: "Damaged Item",
    transactionVoid: "Transaction Void",
    incorrectPurchase: "Incorrect Purchase",
    gasCylinderDeposit: "Gas Cylinder Deposit",
  };

  const [open, setOpen] = useState(isOpen || false);
  const [refundReason, setRefundReason] = useState(reasons.damagedItem);
  const orderDispatch = useOrderDispatch();

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleConfirmClick = () => {
    if (type === "item") {
      setItemToRefund(itemId, refundReason, orderDispatch);
    }
    if (type === "order") {
      setRefundMode(true, refundReason, orderDispatch);
    }
    if (type === "order") {
      onClose();
    }
    setOpen(false);
  };

  const handleCancelClick = () => {
    if (type === "order") {
      onClose();
    }
    setOpen(false);
  };

  const handleSelectReasonChanged = (e) => {
    setRefundReason(e.target.value);
  };

  return (
    <>
      {type === "order" ? (
        <></>
      ) : (
        <Button variant="text" onClick={() => setOpen(true)}>
          Refund
        </Button>
      )}
      <Dialog open={open} p={5}>
        <DialogTitle textAlign="center">Refund Reason</DialogTitle>
        <DialogContentText textAlign="center" mb={3}>
          Select reason for refund:
        </DialogContentText>
        <Grid container alignItems="center" justifyContent="center">
          <Grid
            sx={{ width: "400px" }}
            xs={6}
            mb={3}
            item
            justifyContent="center"
            alignContent="center"
          >
            <FormControl fullWidth>
              <InputLabel id="select-label">Reason</InputLabel>
              <Select
                labelId="select-label"
                id="select"
                label="Reason"
                value={refundReason}
                onChange={(e) => handleSelectReasonChanged(e)}
              >
                <MenuItem value={reasons.damagedItem}>
                  {reasons.damagedItem}
                </MenuItem>
                <MenuItem value={reasons.transactionVoid}>
                  {reasons.transactionVoid}
                </MenuItem>
                <MenuItem value={reasons.incorrectPurchase}>
                  {reasons.incorrectPurchase}
                </MenuItem>
                <MenuItem value={reasons.gasCylinderDeposit}>
                  {reasons.gasCylinderDeposit}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <DialogActions>
          <Button onClick={handleCancelClick} color="error">
            Cancel
          </Button>
          <Button onClick={handleConfirmClick} color="success">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
