import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import { useOrder, useOrderDispatch } from "../../context/Order/OrderContext";
import { useEffect, useRef, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useSnackbarMessage } from "../../context/SnackbarMessageContext";
import {
  addItem,
  getProductByPluAsync,
  setQtyField,
} from "../../context/Order/orderActions";
import { useUI } from "../../context/UIContext";

export default function ItemScanCard({ pluInputRef }) {
  const dispatch = useOrderDispatch();
  const order = useOrder();
  const [plu, setPlu] = useState("");
  const ui = useUI();
  const { instance } = useMsal();
  const { triggerMessage } = useSnackbarMessage();
  const [multiProducts, setMultiProducts] = useState([]);

  const handleAddOnClick = async () => {
    setMultiProducts([]);
    if (plu.length > 0) {
      var response = await getProductByPluAsync(plu, instance, true);
      if (response.ok) {
        var data = await response.json();
        if (data.length > 1) {
          setMultiProducts(data);
        }
        if (data.length === 1) {
          addItem(
            { plu, qty: Number(order.qtyField), ...data[0] },
            order.refundMode,
            dispatch
          );
          setPlu("");
          setQtyField(1, dispatch);
          pluInputRef.current.focus();
        }
      } else {
        if (response.status === 404) {
          triggerMessage("Item Not Found", "error");
          setPlu("");
          setQtyField(1, dispatch);
          pluInputRef.current.focus();
        }
      }
    }
  };

  const handleMultipleProductSelect = (product) => {
    addItem(
      { plu, qty: Number(order.qtyField), ...product },
      order.refundMode,
      dispatch
    );
    setPlu("");
    setQtyField(1, dispatch);
    setMultiProducts([]);
    pluInputRef.current.focus();
  };

  useEffect(() => {
    pluInputRef.current.focus();
  }, [pluInputRef, ui.focus]);

  const handleAddOnKeyUp = async (event) => {
    if (event.key === "*") {
      const qty = plu.replace("*", "");
      setQtyField(qty, dispatch);
      setPlu("");
    }
    if (event.key === "Enter") {
      await handleAddOnClick();
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Card sx={{ backgroundColor: "#f6f6f6" }}>
        <CardContent>
          <Box component="form" noValidate autoComplete="off">
            <Grid
              spacing={1}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <TextField
                  sx={{ backgroundColor: "#fff" }}
                  margin="normal"
                  label="Quantity"
                  type="number"
                  value={order.qtyField}
                  onChange={(e) =>
                    setQtyField(Number(e.target.value), dispatch)
                  }
                ></TextField>
              </Grid>
              <Grid item>
                <TextField
                  inputRef={pluInputRef}
                  sx={{ backgroundColor: "#fff" }}
                  autoFocus
                  margin="normal"
                  label="PLU"
                  value={plu}
                  onChange={(e) => setPlu(e.target.value)}
                  onKeyUp={(e) => handleAddOnKeyUp(e)}
                ></TextField>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddOnClick}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
            <Grid
              spacing={1}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Box
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                  }}
                >
                  <List>
                    {multiProducts.map((product) => (
                      <ListItem>
                        <ListItemButton
                          onClick={(e) => handleMultipleProductSelect(product)}
                        >
                          <ListItemText
                            primary={product.name}
                            secondary={product.sku}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Grid>
            </Grid>
            <Grid item >
                  <div
                    className="multi-colour-text"
                    style={{ display: "flex", alignItems: "center", fontSize: "10px" }}
                  >
                    <span class="material-symbols-outlined">stack_star</span>
                    <span>= Assorted Box</span>
                  </div>
                </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
