import { CurrencyPound, PercentOutlined } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContentText,
  Grid,
  TextField,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  DialogActions,
  Button,
} from "@mui/material";
import { useState } from "react";
import {
  useOrder,
  useOrderDispatch,
} from "../../../context/Order/OrderContext";
import { discountTypes } from "../../../constants/discountTypes";
import { changeOrderDiscount } from "../../../context/Order/orderActions";

export default function DiscountDialogButton() {
  const { orderDiscount, orderDiscountType } = useOrder();

  const [discountType, setDiscountType] = useState(
    orderDiscountType || discountTypes.percent
  );
  const [amount, setAmount] = useState(orderDiscount || 0);
  const [open, setOpen] = useState(false);
  const orderDispatch = useOrderDispatch();


  const handleAmountChanged = (e) => {
    setAmount(e.target.value);
  };

  const handleToggleDiscountType = (newValue) => {
    setDiscountType(newValue);
  };

  const handleConfirmClick = () => {
    let discount = amount;
    if (!amount) {
      setAmount(0);
      discount = 0;
    }
    changeOrderDiscount(discount, discountType, orderDispatch);
    setOpen(false);
  };

  const handleCancelClick = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Discount
      </Button>
      <Dialog open={open}>
        <DialogTitle>Apply Order Discount</DialogTitle>
        <DialogContentText p={3}>
          Select type of discount to apply:
        </DialogContentText>
        <Grid
          mb={2}
          pl={3}
          container
          spacing={3}
          alignItems="center"
          justifyContent="center"
        >
          <Grid xs={5} item justifyContent="center" alignContent="center">
            <TextField
              sx={{ width: "12ch" }}
              InputProps={{
                endAdornment:
                  discountType === discountTypes.percent ? (
                    <InputAdornment position="end">%</InputAdornment>
                  ) : (
                    <></>
                  ),
                startAdornment:
                  discountType === discountTypes.pounds ? (
                    <InputAdornment position="start">£</InputAdornment>
                  ) : (
                    <></>
                  ),
              }}
              value={amount}
              onChange={(e) => handleAmountChanged(e)}
              size="small"
            ></TextField>
          </Grid>
          <Grid xs={5} item justifyContent="center" alignItems="center">
            <ToggleButtonGroup
              size="small"
              exclusive={true}
              value={discountType}
            >
              <ToggleButton
                value={discountTypes.percent}
                onClick={() => handleToggleDiscountType(discountTypes.percent)}
              >
                <PercentOutlined sx={{ width: "16px" }} />
              </ToggleButton>
              <ToggleButton
                value={discountTypes.pounds}
                onClick={() => handleToggleDiscountType(discountTypes.pounds)}
              >
                <CurrencyPound sx={{ width: "16px" }} />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
        <DialogActions>
          <Button onClick={handleCancelClick} color="error">
            Cancel
          </Button>
          <Button onClick={handleConfirmClick} color="success">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
