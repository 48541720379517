import { darken, lighten, styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableCell,
  TableSortLabel,
  Checkbox,
  Box,
  TableRow,

} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGridApiRef } from "@mui/x-data-grid";
import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from "@mui/icons-material";

const headCells = [
  { id: "plu", headerName: "PLU", width: 130, numeric: false, },
  { id: "sku", headerName: "Product Code", width: 130, numeric: false, },
  { id: "description", headerName: "Description", width: 500, numeric: false, },
  {
    id: "stockQuantity",
    headerName: "Quantity",
    type: "number",
    width: 125, numeric: false,
  },
  { id: "isLive", headerName: "Product Live", type: "bool", width: 125, numeric: false, },
  {
    id: "isOutOfStock",
    headerName: "Out Of Stock",
    type: "bool",
    width: 150, numeric: false,
  },
  { id: "otherBulkLocations", headerName: "Other Locations", width: 150, numeric: false, },
];

// const getBackgroundColor = (color, mode) =>
//   mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

// const getHoverBackgroundColor = (color, mode) =>
//   mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

// const getSelectedBackgroundColor = (color, mode) =>
//   mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

// const getSelectedHoverBackgroundColor = (color, mode) =>
//   mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);




function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.headerName}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ProductTable({
  tableRows,
  handleDelete,
  dataType,
  nextRow,
  previousRow,
  loading,
}) {

  const navigate = useNavigate();
  const handleHomeButton = () => {
    navigate("/");
  };

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('plu');
  const [selected, setSelected] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(tableRows);
  }, [tableRows]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    // order rows here
    rows.sort((a, b) => {
      if (isAsc) {
        return a[property] > b[property] ? -1 : 1;
      } else {
        return a[property] < b[property] ? -1 : 1;
      }
    });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tableRows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div style={{ margin: "10px" }}>
      <div style={{ height: 500, width: "100%" }}>
        <TableContainer sx={{ height: 500, width: "100%" }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tableRows.length}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                let fontWeight = row.fragile ? 'bold' : 'normal'
                let backgroundColour = !row.isLive ? 'rgb(255, 224, 178)' : 'white';
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer', fontWeight: fontWeight, backgroundColor: backgroundColour }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell component="th"
                      id={labelId}
                      scope="row"
                      sx={{ fontWeight: fontWeight }}
                      padding="none">{row.plu}</TableCell>
                    <TableCell sx={{ fontWeight: fontWeight }}>{row.sku}</TableCell>
                    <TableCell sx={{ fontWeight: fontWeight }}>{row.description}</TableCell>
                    <TableCell sx={{ fontWeight: fontWeight }}>{row.stockQuantity}</TableCell>
                    <TableCell sx={{ fontWeight: fontWeight }}>{row.isLive ? <CheckBoxOutlined></CheckBoxOutlined> : <CheckBoxOutlineBlankOutlined></CheckBoxOutlineBlankOutlined>}</TableCell>
                    <TableCell sx={{ fontWeight: fontWeight }}>{row.isOutOfStock ? <CheckBoxOutlined></CheckBoxOutlined> : <CheckBoxOutlineBlankOutlined></CheckBoxOutlineBlankOutlined>}</TableCell>
                    <TableCell sx={{ fontWeight: fontWeight }}>{row.otherBulkLocations}</TableCell>

                  </TableRow>
                );
              })}
              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{ textAlign: "center", margin: "20px" }}>
        <Button
          style={{ margin: "20px" }}
          variant="contained"
          size="large"
          onClick={handleHomeButton}
        >
          Home
        </Button>
        <Button
          style={{ margin: "20px" }}
          variant="contained"
          size="large"
          onClick={() => {
            handleDelete(selected);
          }}
        >
          Delete Row
        </Button>
        <Button
          style={{ margin: "20px" }}
          variant="contained"
          size="large"
          onClick={() => previousRow()}
        >
          Previous Location
        </Button>
        <Button
          style={{ margin: "20px" }}
          variant="contained"
          size="large"
          onClick={() => nextRow()}
        >
          Next Location
        </Button>
      </div>
    </div >
  );
}
