import { createContext, useContext, useReducer } from "react";
import { callApiAsync } from "../utils/apiUtil";

const initialCategories = [];

export const CategoriesContext = createContext(null);
export const CategoriesDispatchContext = createContext(null);

export function CategoriesProvider({ children }) {
  const [categories, dispatch] = useReducer(itemsReducer, initialCategories);

  return (
    <CategoriesContext.Provider value={categories}>
      <CategoriesDispatchContext.Provider value={dispatch}>
        {children}
      </CategoriesDispatchContext.Provider>
    </CategoriesContext.Provider>
  );
}

function itemsReducer(categories, action) {

  switch (action.type) {
    case "load-initial-data": {
      return {
        categoryItems: action.categories,
        parentCategoryId: 0,
        categoryName: "Home",
        products: [],
        history: [{ id: 0, name: "Home" }],
      };
    }
    case "select-category": {
      let result = {
        categoryItems: action.categories,
        parentCategoryId: action.parentCategoryId,
        products: action.products,
        history: [
          ...categories.history,
          { id: action.parentCategoryId, name: action.categoryName },
        ],
        categoryName: action.categoryName,
      };
      return result;
    }
    case "select-product": {
      let result = {
        categoryItems: action.categories,
        parentCategoryId: action.parentCategoryId,
        products: action.products,
        history: [
          ...categories.history,
          { id: action.parentCategoryId, name: action.categoryName },
        ],
        categoryName: action.categoryName,
      };
      return result;
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}

export async function loadCategoriesAsync(msalInstance, dispatch) {
  const response = await callApiAsync(
    msalInstance,
    null,
    "GET",
    "api/Category"
  );
  const data = await response.json();
  dispatch({
    type: "load-initial-data",
    categories: data,
  });
}

export async function selectCategoryAsync(
  newCategoryId,
  newCategoryName,
  msalInstance,
  dispatch
) {
  const response = await callApiAsync(
    msalInstance,
    null,
    "GET",
    `api/Category?parentCategoryId=${newCategoryId}`
  );

  const data = await response.json();
  dispatch({
    type: "select-category",
    categories: data,
    products: [],
    parentCategoryId: data[0].parentCategoryId,
    categoryName: newCategoryName,
  });
}

export async function selectProductAsync(
  newCategoryId,
  newCategoryName,
  msalInstance,
  dispatch
) {
  let result = await callApiAsync(
    msalInstance,
    null,
    "GET",
    `api/category/${newCategoryId}/products`
  );
  const data = await result.json();
  dispatch({
    type: "select-category",
    products: data,
    categories: [],
    parentCategoryId: newCategoryId,
    categoryName: newCategoryName,
  });
}

export function useCategories() {
  return useContext(CategoriesContext);
}

export function useCategoriesDispatch() {
  return useContext(CategoriesDispatchContext);
}
