import { createContext, useContext, useEffect, useReducer } from "react";
import { orderActions, itemActions, changeItemDiscount } from "./orderActions";
import { discountTypes } from "../../constants/discountTypes";
import { ChangeOrder, ConvertOrderToModel } from "../../services/apiService";
import { useMsal } from "@azure/msal-react";
import { formatISO, parseISO } from "date-fns";

const initialOrderData = JSON.parse(sessionStorage.getItem("posOrder")) || {
  items: [],
  refundItems: [],
  vatPercentage: 20,
  orderDiscount: 0,
  orderDiscountType: discountTypes.pounds,
  orderDiscountAmount: 0,
  netTotal: 0,
  total: 0,
  totalItems: 0,
  vatAmount: 0,
  payments: [],
  refundMode: false,
  refundReason: null,
  refundAmount: 0,
  account: null,
  qtyField: 1,
};

export const OrderModel = initialOrderData.Type;
let initialSetup = true;
export const OrderContext = createContext(null);
export const OrderDispatchContext = createContext(null);

export function OrderProvider({ children }) {
  const [order, dispatch] = useReducer(orderReducer, initialOrderData);
  const { instance } = useMsal();

  useEffect(() => {
    const updateOrder = async () => {
      let orderData = ConvertOrderToModel(order);
      if (order.items.length === 0 && order.payments.length === 0) return;
      dispatch({
        type: orderActions.SET_IS_LOADING,
        loading: true,
      });
      let response = await ChangeOrder(orderData, instance);
      let json = await response.json();
      console.log(json);
      dispatch({
        type: orderActions.SET_ORDER_ID,
        id: json.id,
        items: json.orderItems,
        payments: json.payments.map((s) => {
           return { ...s, paymentDate: parseISO(s.paymentDate) };
        }),
      });
      dispatch({
        type: orderActions.SET_IS_LOADING,
        loading: false,
      });
    };

    sessionStorage.setItem("posOrder", JSON.stringify(order));
    if (initialSetup) {
      initialSetup = false;
    } else {
      console.log("updating order");
      updateOrder();
    }
  }, [
    order.items,
    order.vatAmount,
    order.vatPercentage,
    order.orderDiscount,
    order.payments.length,
    order.account,
    order.refundAmount,
    order.total,
  ]);

  return (
    <OrderContext.Provider value={order}>
      <OrderDispatchContext.Provider value={dispatch}>
        {children}
      </OrderDispatchContext.Provider>
    </OrderContext.Provider>
  );

  function orderReducer(order, action) {
    console.log(action);
    switch (action.type) {
      case orderActions.SET_IS_LOADING: {
        return {
          ...order,
          isLoading: action.loading,
        };
      }

      case orderActions.SET_REFUND_MODE: {
        return {
          ...order,
          refundMode: action.refundMode,
          refundReason: action.refundReason,
        };
      }

      case orderActions.SET_ORDER_ID: {
        for (let index = 0; index < action.items.length; index++) {
          const dbItem = action.items[index];
          let existingItemIndex = order.items.findIndex(
            (i) => i.id === dbItem.id
          );
          if (existingItemIndex === -1) {
            let itemIndex = order.items.findIndex(
              (i) => i.productId === dbItem.productId && i.id === null
            );
            if (itemIndex > -1) {
              console.log(order.items[itemIndex]);
              order.items[itemIndex].id = dbItem.id;
            }
          }
        }
        console.log(action.payments);
        order.payments = action.payments;

        return {
          ...order,
          id: action.id,
          orderNumber: action.id.toString(),
          items: order.items,
        };
      }

      case orderActions.RESET_ORDER: {
        sessionStorage.setItem("posOrder", JSON.stringify(action.order));
        return action.order;
      }

      case itemActions.ADD_ITEM: {
        let existingItem = order.items.find(
          (t) =>
            t.productId === action.data.id && t.isRefund === action.isRefund
        );

        let shouldCombineItems = existingItem;

        if (
          action.data.sku === "Misc" ||
          action.data.sku === "Silks" ||
          action.data.sku === "Sundries" ||
          action.data.sku === "Delivery"
        ) {
          shouldCombineItems = false;
        }

        let unitPrice = calculateUnitPriceWithDiscounts(
          action.data.qty,
          action.data.price,
          action.data.discounts,
          action.data.tierPrices
        );

        unitPrice = order.refundMode ? -unitPrice : unitPrice;

        if (!shouldCombineItems) {
          let discount = 0;
          let discountAmount = 0;
          let discountType = discountTypes.percent;
          if (order.account?.isWholesaler) {
            let discountInfo = action.data.discounts[0];
            discountType = discountInfo.isPercentage
              ? discountTypes.percent
              : discountTypes.pounds;
            discount = discountInfo.isPercentage
              ? discountInfo.percentage
              : discountInfo.amount;
            discountAmount = calculateDiscountAmountForItem(
              action.data.qty,
              unitPrice,
              discount,
              discountType
            );
          }
          let items = [
            {
              id: null,
              productId: action.data.id,
              plu: action.data.plu,
              sku: action.data.sku,
              qty: action.data.qty,
              description: action.data.name,
              assortedBox: action.data.assortedBox,
              unitPrice: unitPrice,
              bulkDiscountApplied: shouldApplyTierPrice(
                action.data.tierPrices,
                action.data.qty
              ),
              productUnitPrice: action.data.price,
              discount: discount,
              discountAmount: discountAmount,
              discountType: discountType,
              discounts: action.data.discounts,
              tierPrices: action.data.tierPrices,
              netPrice: calculateNetItemPrice(
                action.data.qty,
                unitPrice,
                discountAmount
              ),
              stockLevel: action.data.stockLevel,
              isRefund: order.refundMode,
              refundReason: order.refundMode ? order.refundReason : null,
            },
            ...order.items,
          ];
          order = {
            ...order,
            items: items,
            ...calculateTotals(order, items),
          };

          order = {
            ...order,
          };
        } else {
          let qty = Number(action.data.qty) + Number(existingItem.qty);

          let itemToChangeIndex = order.items.findIndex(
            (t) =>
              t.productId === action.data.id && t.isRefund === action.isRefund
          );
          let itemToChange = order.items[itemToChangeIndex];

          itemToChange.qty = qty;
          itemToChange.unitPrice = calculateUnitPriceWithDiscounts(
            qty,
            itemToChange.productUnitPrice,
            itemToChange.discounts,
            itemToChange.tierPrices
          );

          itemToChange.discountAmount = calculateDiscountAmountForItem(
            itemToChange.qty,
            itemToChange.unitPrice,
            itemToChange.discount,
            itemToChange.discountType
          );
          itemToChange.netPrice = calculateNetItemPrice(
            itemToChange.qty,
            itemToChange.unitPrice,
            itemToChange.discountAmount
          );

          itemToChange.bulkDiscountApplied = shouldApplyTierPrice(
            action.data.tierPrices,
            action.data.qty
          );

          order = { ...order, ...calculateTotals(order, order.items) };
        }
        return {
          ...order,
        };
      }

      case itemActions.CHANGE_ITEM_DISCOUNT: {
        if (!action.discount) {
          action.discount = 0;
        }

        let itemToChangeIndex = order.items.findIndex(
          (i) => i.id === action.id && i.isRefund === action.isRefund
        );

        let signMultiplier = order.refundMode ? -1 : 1;

        let itemToChange = order.items[itemToChangeIndex];
        itemToChange.discount = signMultiplier * action.discount;
        itemToChange.discountType = action.discountType;
        itemToChange.discountAmount = calculateDiscountAmountForItem(
          itemToChange.qty,
          itemToChange.unitPrice,
          itemToChange.discount,
          itemToChange.discountType
        );
        itemToChange.netPrice = calculateNetItemPrice(
          itemToChange.qty,
          itemToChange.unitPrice,
          itemToChange.discountAmount
        );

        return { ...order, ...calculateTotals(order, order.items) };
      }

      case itemActions.CHANGE_ITEM_QUANTITY: {
        let itemToChangeIndex = order.items.findIndex(
          (i) => i.id === action.id && i.isRefund === action.isRefund
        );
        let itemToChange = order.items[itemToChangeIndex];
        let signMultiplier = itemToChange.isRefund ? -1 : 1;
        itemToChange.qty = action.qty;
        itemToChange.unitPrice =
          signMultiplier *
          calculateUnitPriceWithDiscounts(
            itemToChange.qty,
            itemToChange.productUnitPrice,
            itemToChange.discounts,
            itemToChange.tierPrices
          );

        itemToChange.discountAmount = calculateDiscountAmountForItem(
          itemToChange.qty,
          itemToChange.unitPrice,
          itemToChange.discount,
          itemToChange.discountType
        );
        itemToChange.netPrice = calculateNetItemPrice(
          itemToChange.qty,
          itemToChange.unitPrice,
          itemToChange.discountAmount
        );

        itemToChange.bulkDiscountApplied = shouldApplyTierPrice(
          itemToChange.tierPrices,
          action.qty
        );
        return { ...order, ...calculateTotals(order, order.items) };
      }

      case itemActions.DELETE_ALL_ITEMS: {
        return { ...order, items: [], ...calculateTotals(order, []) };
      }

      case itemActions.DELETE_LAST_ITEM: {
        order.items.pop();
        return { ...order, ...calculateTotals(order, order.items) };
      }

      case itemActions.DELETE_ITEM: {
        let itemToRemoveIndex = order.items.findIndex(
          (i) => i.id === action.id && i.isRefund === action.isRefund
        );
        if (itemToRemoveIndex > -1) {
          let items = order.items.toSpliced(itemToRemoveIndex, 1);
          return { ...order, items: items, ...calculateTotals(order, items) };
        } else {
          return order;
        }
      }

      case itemActions.SET_ITEM_TO_REFUND: {
        let itemToRefundIndex = order.items.findIndex(
          (i) => i.id === action.id
        );

        if (itemToRefundIndex >= 0) {
          let item = {
            ...order.items[itemToRefundIndex],
            isRefund: true,
            refundReason: action.refundReason,
          };
          item.unitPrice = -item.unitPrice;
          item.discountAmount = calculateDiscountAmountForItem(
            item.qty,
            item.unitPrice,
            item.discount,
            item.discountType
          );
          item.netPrice = calculateNetItemPrice(
            item.qty,
            item.unitPrice,
            item.discountAmount
          );
          let items = order.items.filter((t) => t.id !== action.id);
          items = [...items, item];
          return { ...order, items, ...calculateTotals(order, items) };
        }

        return order;
      }
      case orderActions.CHANGE_VAT_PERCENTAGE: {
        let newOrder = {
          ...order,
          vatPercentage: action.vatPercentage,
        };
        return { ...newOrder, ...calculateTotals(newOrder, newOrder.items) };
      }

      case orderActions.CHANGE_ORDER_DISCOUNT: {
        let newOrder = {
          ...order,
          orderDiscount: action.orderDiscount,
          orderDiscountType: action.orderDiscountType,
        };
        return {
          ...newOrder,
          ...calculateTotals(newOrder, newOrder.items),
        };
      }

      case orderActions.ADD_PAYMENT: {
        let payments = [
          ...order.payments,
          {
            paymentAmount: action.paymentAmount,
            paymentType: action.paymentType,
            paymentDate: action.paymentDate,
          },
        ];
        return { ...order, payments };
      }

      case orderActions.SET_QTY_FIELD: {
        return { ...order, qtyField: action.qty };
      }

      case orderActions.REMOVE_PAYMENT: {
        let payments = order.payments.filter((t, i) => i !== action.i);
        return { ...order, payments };
      }

      case orderActions.SET_ACCOUNT: {
        let items = order.items;
        if (action.account?.isWholesaler) {
          items = items.map((item) => {
            let discountInfo = item.discounts[0];
            if (discountInfo) {
              item.discountType = discountInfo.isPercentage
                ? discountTypes.percent
                : discountTypes.pounds;
              item.discount = discountInfo.isPercentage
                ? discountInfo.percentage
                : discountInfo.amount;
              let discountAmount = calculateDiscountAmountForItem(
                item.qty,
                item.unitPrice,
                item.discount,
                item.discountType
              );

              let netPrice = calculateNetItemPrice(
                item.qty,
                item.unitPrice,
                discountAmount
              );

              let newItem = {
                ...item,
                discountAmount: discountAmount,
                netPrice: netPrice,
                discount: item.discount,
                discountType: item.discountType,
              };
              return newItem;
            } else {
              return item;
            }
          });
        } else {
        }
        let newOrder = { ...order, items };
        return { ...newOrder, account: action.account };
      }

      case orderActions.LOAD_ORDER: {
        action.order.items.forEach((element) => {
          element.bulkDiscountApplied = shouldApplyTierPrice(
            element.tierPrices,
            element.qty
          );
        });
        return { ...action.order };
      }

      case orderActions.SET_PLU_INPUT_REF: {
        return { ...order, pluInputRef: action.pluRef };
      }

      case orderActions.FOCUS_PLU_INPUT: {
        order.pluInputRef.current.focus();
        return order;
      }

      default: {
        throw Error("Unknown action: " + action.type);
      }
    }
  }
}

const shouldApplyTierPrice = (tierPrices, qty) => {
  if (!tierPrices || tierPrices.length === 0) return false;
  let tierPriceToApply = tierPrices.find((t) => t.quantity <= qty);
  if (tierPriceToApply) return true;
  return false;
};

const calculateUnitPriceWithDiscounts = (
  qty,
  productUnitPrice,
  discounts,
  tierPrices
) => {
  if (!tierPrices || tierPrices.length === 0) return productUnitPrice;
  let tierPriceToApply = tierPrices.find((t) => t.quantity <= qty);
  if (tierPriceToApply) return tierPriceToApply.price;
  return productUnitPrice;
};

export const calculateNetItemPrice = (qty, unitPrice, discountAmount) =>
  qty * unitPrice - discountAmount;

export const calculateDiscountAmountForItem = (
  qty,
  unitPrice,
  discount,
  discountType
) => {
  if (discountType === discountTypes.percent) {
    return (Number(discount) / 100) * (Number(qty) * Number(unitPrice));
  }

  if (discountType === discountTypes.pounds) {
    return Number(discount);
  }
};

export const calculateTotals = (order, items) => {
  let discountAmount = calculateDiscountAmount(
    order.orderDiscount,
    order.orderDiscountType,
    items.reduce((a, c) => a + Number(c.netPrice), 0)
  );
  let netTotal =
    items.reduce((a, c) => (c.isRefund ? a + 0 : a + Number(c.netPrice)), 0) +
    discountAmount;
  let refundAmount = items.reduce(
    (a, c) => (c.isRefund ? a + Number(c.netPrice) : a + 0),
    0
  );
  console.log(refundAmount);
  let refundVat = (order.vatPercentage / 100) * refundAmount;
  let vatAmount = (order.vatPercentage / 100) * netTotal + refundVat;
  let total = vatAmount + netTotal + refundAmount;
  let totalItems = items.reduce(
    (a, c) => (c.isRefund ? a - Number(c.qty) : a + Number(c.qty)),
    0
  );

  return {
    discountAmount,
    netTotal,
    vatAmount,
    refundAmount,
    total,
    totalItems,
  };
};

export const calculateDiscountAmount = (
  discount,
  discountType,
  currentTotal
) => {
  if (discountType === discountTypes.percent) {
    return -(discount / 100) * currentTotal;
  }

  if (discountType === discountTypes.pounds) {
    return -discount;
  }
};

export function useOrder() {
  return useContext(OrderContext);
}

export function useOrderDispatch() {
  return useContext(OrderDispatchContext);
}
