export const openDrawer = async (cashDrawer) => {
    try {
      await cashDrawer.open({ baudRate: 9600 });
      let r = await cashDrawer.getSignals();
      console.log(r);
      let writer = cashDrawer.writable.getWriter();
      let message = "open";
      let encoder = new TextEncoder();
      let data = encoder.encode(message);
      await writer.write(data);
      writer.releaseLock();
      await cashDrawer.close();
    } catch (e) {}
  };