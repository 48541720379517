import { discountTypes } from "../../constants/discountTypes";
import { ChangeOrder, ConvertOrderToModel } from "../../services/apiService";
import { callApiAsync } from "../../utils/apiUtil";

export const itemActions = {
  ADD_ITEM: "add-item",
  CHANGE_ITEM_DISCOUNT: "change-item-discount",
  CHANGE_ITEM_QUANTITY: "change-item-quantity",
  DELETE_ALL_ITEMS: "delete-all-items",
  DELETE_LAST_ITEM: "delete-last-item",
  DELETE_ITEM: "delete-item",
  SET_ITEM_TO_REFUND: "set-item-to-refund",
};

export const orderActions = {
  CHANGE_VAT_PERCENTAGE: "change-vat-percentage",
  CHANGE_ORDER_DISCOUNT: "change-order-discount",
  RESET_ORDER: "reset-order",
  ADD_PAYMENT: "add-payment",
  REMOVE_PAYMENT: "remove-payment",
  SET_ACCOUNT: "set-account",
  LOAD_ORDER: "load-order",
  SET_ORDER_ID: "set-order-id",
  SET_REFUND_MODE: "set-refund-mode",
  SET_QTY_FIELD: "set-qty-field",
  SET_IS_LOADING: "set-is-loading",
};

export async function addItem(data, isRefund, dispatch) {
  dispatch({
    type: itemActions.ADD_ITEM,
    isRefund: isRefund,
    data,
  });
}

export async function setRefundMode(refundMode, refundReason, dispatch) {
  dispatch({
    type: orderActions.SET_REFUND_MODE,
    refundMode: refundMode,
    refundReason: refundReason,
  });
}

export async function setItemToRefund(id, refundReason, dispatch) {
  dispatch({
    type: itemActions.SET_ITEM_TO_REFUND,
    id: id,
    refundReason: refundReason,
  });
}

export function addPayment(paymentAmount, paymentType, dispatch) {
  dispatch({
    type: orderActions.ADD_PAYMENT,
    paymentAmount,
    paymentType,
    paymentDate: Date.now(),
  });
}

export function removePayment(i, dispatch) {
  dispatch({
    type: orderActions.REMOVE_PAYMENT,
    i: i,
  });
}

export function changeItemDiscount(
  id,
  discount,
  discountType,
  isRefund,
  dispatch
) {
  dispatch({
    type: itemActions.CHANGE_ITEM_DISCOUNT,
    id: id,
    discount: discount,
    discountType: discountType,
    isRefund: isRefund,
  });
}

export function changeOrderDiscount(discount, discountType, dispatch) {
  dispatch({
    type: orderActions.CHANGE_ORDER_DISCOUNT,
    orderDiscount: discount,
    orderDiscountType: discountType,
  });
}

export async function resetOrder(dispatch) {
  let order = {
    id: null,
    items: [],
    orderNumber: "",
    vatPercentage: 20,
    orderDiscount: 0,
    orderDiscountType: discountTypes.pounds,
    netTotal: 0,
    total: 0,
    totalItems: 0,
    vatAmount: 0,
    payments: [],
    refundMode: false,
    qtyField: 1,
  };

  dispatch({
    type: orderActions.RESET_ORDER,
    order,
  });
}

export function changeItemQty(id, qty, isRefund, dispatch) {
  dispatch({ type: itemActions.CHANGE_ITEM_QUANTITY, id, qty, isRefund });
}

export function deleteAllItems(dispatch) {
  dispatch({ type: itemActions.DELETE_ALL_ITEMS });
}

export function deleteLastItem(dispatch) {
  dispatch({ type: itemActions.DELETE_LAST_ITEM });
}

export function deleteItem(id, isRefund, dispatch) {
  dispatch({ type: itemActions.DELETE_ITEM, id: id, isRefund: isRefund });
}

export function updateVatPercentage(vatPercentage, dispatch) {
  dispatch({
    type: orderActions.CHANGE_VAT_PERCENTAGE,
    vatPercentage: vatPercentage,
  });
}

export function setAccount(account, dispatch) {
  dispatch({
    type: orderActions.SET_ACCOUNT,
    account: account,
  });
}

export function setQtyField(qty, dispatch) {
  dispatch({
    type: orderActions.SET_QTY_FIELD,
    qty: qty,
  });
}

export async function getProductByPluAsync(
  plu,
  instance,
  showUnpublished = true
) {
  return await callApiAsync(
    instance,
    null,
    "GET",
    `/api/product?plu=${plu}&showUnpublished=${showUnpublished}`
  );
}



export const loadOrderFromApi = (order, dispatch) => {
  let orderModel = {
    id: order.id,
    existingOrder: true,
    netTotal: order.orderSubTotal,
    vatAmount: order.taxAmount,
    vatPercentage: order.taxRate,
    orderDiscount: order.orderDiscount ?? 0,
    total: order.orderTotal,
    orderDiscountType: discountTypes.pounds,
    account: order.account,
    refundAmount: order.refundAmount,
    qtyField: 1,
    payments: order.payments.map((p) => {
      return {
        id: p.id,
        paymentAmount: p.paymentAmount,
        paymentType: p.paymentType,
        paymentDate: Date.parse(p.paymentDate),
      };
    }),
    items: order.orderItems.map((i) => {
      return {
        ...i,
        id: i.id,
        productId: i.productId,
        discount: i.discountAmount ?? 0,
        discountType: discountTypes.pounds,
        netPrice: i.netPrice,
        price: i.price,
        productUnitPrice: i.productUnitPrice,
        description: i.name,
        discounts: i.discounts,
        tierPrices: i.tierPrices,
        assortedBox: i.assortedBox,
      };
    }),
  };
  dispatch({ type: orderActions.LOAD_ORDER, order: orderModel });
};

export const layawayOrder = async (order, instance, dispatch) => {
  let orderData = ConvertOrderToModel(order);
  if (order.items.length === 0 && order.payments.length === 0) return;
  let response = await ChangeOrder(orderData, instance);
  let json = await response.json();
  resetOrder(dispatch);
};
