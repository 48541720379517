import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import {
  selectCategoryAsync,
  selectProductAsync,
  useCategoriesDispatch,
} from "../../../context/CategoriesContext";

export default function CategoryCard(props) {
  const { instance } = useMsal();

  const dispatch = useCategoriesDispatch();

  const handleCardClick = async () => {
    if (!props.hasProducts) {
      await selectCategoryAsync(props.id, props.name, instance, dispatch);
    } else {
      await selectProductAsync(props.id, props.name, instance, dispatch);
    }
  };

  return (
    <div>
      <Card sx={{ cursor: "pointer" }} onClick={() => handleCardClick()}>
        <CardMedia
          sx={{ height: 90, backgroundSize: '90px 90px' }}
          image={props.pictureUrl}
          title={props.name}
        ></CardMedia>
        <CardContent>
          <Typography align="center" variant="subtitle2">
            {props.name}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}
