import { Box, Container, Grid, Paper } from "@mui/material";
import MainTabs from "../components/PointOfSale/MainTabs";
import ItemScanCard from "../components/PointOfSale/ItemScanCard";
import SidePanel from "../components/PointOfSale/SidePanel";
import { PointOfSaleProviders } from "../context/PointOfSaleProviders";
import { useRef } from "react";

export default function PointOfSale() {
  const pluInputRef = useRef(null);
  return (
    <PointOfSaleProviders>
      <Container sx={{ height: "100%" }} fixed maxWidth="xl">
        <Paper sx={{ height: "100%" }}>
          <Box
            sx={{
              width: "65%",
              float: "left",
              padding: "5px",
              height: "100%",
            }}
          >
            <Grid container item xs={12}>
              <ItemScanCard pluInputRef={pluInputRef}></ItemScanCard>
            </Grid>
            <Grid container item>
              <MainTabs></MainTabs>
            </Grid>
          </Box>
          <Box
            sx={{
              width: "25%",
              padding: "5px",
              float: "left",
              height: "100%",
            }}
          >
            <SidePanel pluInputRef={pluInputRef}></SidePanel>
          </Box>
        </Paper>
      </Container>
    </PointOfSaleProviders>
  );
}
