import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Box,
  Card,
  CardContent,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Checkbox,
} from "@mui/material";
import {
  useOrder,
  useOrderDispatch,
} from "../../../context/Order/OrderContext";
import { forwardRef, useCallback, useEffect, useState } from "react";
import { Table } from "reactstrap";
import { useMsal } from "@azure/msal-react";
import { format } from "date-fns";
import {
  generateCreditNotePdf,
  generateDeliveryNotePdf,
  generateInvoicePdf,
  getOrders,
} from "../../../services/apiService";
import { loadOrderFromApi } from "../../../context/Order/orderActions";
import { DatePicker } from "@mui/x-date-pickers";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrdersDialogButton({ customerId }) {
  const [open, setOpen] = useState(false);
  const [showLayawayOrders, setLayawayOrders] = useState(false);
  const [orders, setOrders] = useState({ items: [] });
  const [action, setAction] = useState();
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const { instance } = useMsal();
  const orderDispatch = useOrderDispatch();
  const order = useOrder();
  let orderAction = {
    invoice: "invoice",
    deliveryNote: "delivery-note",
    creditNote: "credit-note",
    loadOrder: "load-order",
    printReceipt: "print-receipt",
  };

  const getOrderData = useCallback(
    async (page, searchTerm, customerId, dateFrom, dateTo, layawayOrders) => {
      let orderResponse = await getOrders(
        page,
        { searchTerm, customerId, dateFrom, dateTo, layawayOrders },
        instance
      );
      if (orderResponse.ok) {
        let orders = await orderResponse.json();
        console.log(orders);
        setOrders(orders);
      }
    },
    [instance]
  );

  useEffect(() => {
    const init = async () => {
      await getOrderData(1, null, customerId, null, null);
    };
    if (open) {
      init();
    }
  }, [getOrderData, customerId, open]);

  const handleClickOpen = () => {
    console.log(orders);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = async (event, value) => {
    setPageNumber(value);
    await getOrderData(
      value,
      searchTerm,
      customerId,
      dateFrom,
      dateTo,
      showLayawayOrders
    );
  };

  const handleActionChange = (e) => {
    setAction(e.target.value);
  };

  const handleSearchClick = async () => {
    setPageNumber(1);
    await getOrderData(
      1,
      searchTerm,
      customerId,
      dateFrom,
      dateTo,
      showLayawayOrders
    );
  };

  const handleLoadOrder = (order) => {
    loadOrderFromApi(order, orderDispatch);
    setOpen(false);
  };

  const handleLayawayCheckboxChange = () => {
    setLayawayOrders(!showLayawayOrders);
  };

  const handleActionClick = async (order) => {
    switch (action) {
      case orderAction.invoice:
        await generateInvoicePdf(order, instance);
        break;
      case orderAction.creditNote:
        await generateCreditNotePdf(order, instance);
        break;
      case orderAction.deliveryNote:
        await generateDeliveryNotePdf(order, instance);
        break;
      case orderAction.loadOrder:
        handleLoadOrder(order);
        break;
      case orderAction.printReceipt:
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        Orders
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }} color="secondary">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Orders
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Paper>
            <Box
              sx={{
                width: "100%",
                float: "left",
                padding: "5px",
              }}
            >
              <Grid container>
                <Grid container item m={1} xs={12} justifyContent="center">
                  <Grid item m={1}>
                    <DatePicker
                      label="Date from"
                      slotProps={{ textField: { size: "small" } }}
                      format="dd-MM-yyyy"
                      value={dateFrom}
                      onChange={(v) => setDateFrom(v)}
                    ></DatePicker>
                  </Grid>
                  <Grid item m={1}>
                    <DatePicker
                      label="Date to"
                      slotProps={{ textField: { size: "small" } }}
                      format="dd-MM-yyyy"
                      value={dateTo}
                      onChange={(v) => setDateTo(v)}
                    ></DatePicker>
                  </Grid>
                  <Grid item m={1}>
                    <TextField
                      type="search"
                      sx={{ backgroundColor: "#fff" }}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      autoFocus
                      size="small"
                      label="Search"
                    ></TextField>
                  </Grid>
                  <Grid item m={1}>
                    <FormControlLabel
                      label="Incomplete POS Orders"
                      control={
                        <Checkbox
                          checked={showLayawayOrders}
                          onChange={handleLayawayCheckboxChange}
                        />
                      }
                    />
                  </Grid>
                  <Grid item m={1}>
                    <Button variant="outlined" onClick={handleSearchClick}>
                      Go
                    </Button>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Box sx={{ width: "100%" }}>
                    <Card sx={{ backgroundColor: "#f6f6f6" }}>
                      <CardContent>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell>
                                <FormControl
                                  sx={{ width: "20ch" }}
                                  size="small"
                                >
                                  <InputLabel id="select-action-label">
                                    Action
                                  </InputLabel>
                                  <Select
                                    labelId="select-action-label"
                                    id="select-action"
                                    value={action}
                                    label="Action"
                                    onChange={handleActionChange}
                                  >
                                    <MenuItem value={"none"}></MenuItem>
                                    <MenuItem value={orderAction.invoice}>
                                      Invoice
                                    </MenuItem>
                                    <MenuItem value={orderAction.deliveryNote}>
                                      Delivery Note
                                    </MenuItem>
                                    <MenuItem value={orderAction.creditNote}>
                                      Credit Note
                                    </MenuItem>
                                    <MenuItem value={orderAction.loadOrder}>
                                      Load Order
                                    </MenuItem>
                                    <MenuItem value={orderAction.printReceipt}>
                                      Print Receipt
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell>Number</TableCell>
                              <TableCell>Date</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell align="center">Total Items</TableCell>
                              <TableCell align="center">
                                Individual Items
                              </TableCell>
                              <TableCell align="center">Total</TableCell>
                              <TableCell>Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {orders.items.map((c, i) => (
                              <TableRow
                                key={i}
                                className={
                                  c.id === order.id ? "current-order" : ""
                                }
                              >
                                <TableCell></TableCell>
                                <TableCell>
                                  <Button
                                    size="small"
                                    onClick={() => handleActionClick(c)}
                                  >
                                    Go
                                  </Button>
                                </TableCell>
                                <TableCell>{c.orderNumber}</TableCell>
                                <TableCell>
                                  {format(
                                    Date.parse(c.orderDateUtc),
                                    "dd/MM/yyyy HH:mm"
                                  )}
                                </TableCell>
                                <TableCell>{c.customerName}</TableCell>
                                <TableCell align="center">
                                  {c.orderItems.reduce((p, a) => p + a.qty, 0)}
                                </TableCell>
                                <TableCell align="center">
                                  {c.orderItems.length}
                                </TableCell>
                                <TableCell align="center">
                                  £{c.orderTotal.toFixed(2)}
                                </TableCell>
                                <TableCell>{c.orderStatus}</TableCell>
                              </TableRow>
                            ))}
                            {orders.items.length === 0 ? (
                              <>
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    colSpan={9}
                                  ></TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="center" colSpan={9}>
                                    <Typography>No Orders</Typography>
                                  </TableCell>
                                </TableRow>
                              </>
                            ) : (
                              <></>
                            )}
                          </TableBody>
                        </Table>

                        <Pagination
                          count={orders.pageCount}
                          page={pageNumber}
                          onChange={handlePageChange}
                        ></Pagination>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </DialogContent>
      </Dialog>
    </div>
  );
}
