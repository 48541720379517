import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  DialogContent,
  Grid,
  InputAdornment,
  Pagination,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  useOrder,
  useOrderDispatch,
} from "../../../context/Order/OrderContext";
import { forwardRef, useCallback, useEffect, useState } from "react";
import { Table } from "reactstrap";
import { useMsal } from "@azure/msal-react";
import { format } from "date-fns";
import { getCustomers } from "../../../services/apiService";
import { setAccount } from "../../../context/Order/orderActions";
import OrdersDialogButton from "./OrdersDialogButton";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AccountsDialogButton() {
  const [open, setOpen] = useState(false);
  const [customers, setCustomers] = useState({ items: [] });
  const [pageNumber, setPageNumber] = useState(1);
  const { instance } = useMsal();
  const order = useOrder();
  const orderDispatch = useOrderDispatch();
  const [searchTerm, setSearchTerm] = useState(null);

  const getCustomerData = useCallback(
    async (page, searchTerm, customerId) => {
      let customerResponse = await getCustomers(
        page,
        { searchTerm, customerId },
        instance
      );
      if (customerResponse.ok) {
        setCustomers(await customerResponse.json());
      }
    },
    [instance]
  );

  useEffect(() => {
    const init = async () => {
      await getCustomerData(1, null, null);
    };
    if (open) {
      init();
    }
  }, [getCustomerData, open]);

  const handleClickOpen = () => {
    console.log(customers);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = async (event, value) => {
    setPageNumber(value);
    await getCustomerData(value, searchTerm, null);
  };

  const handleSelectAccountClick = (account) => {
    setAccount(account, orderDispatch);
    setOpen(false);
  };

  const handleSearchClick = async () => {
    setPageNumber(1);
    await getCustomerData(1, searchTerm, null);
  };

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        Accounts
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }} color="secondary">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Accounts
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Paper>
            <Box
              sx={{
                width: "100%",
                float: "left",
                padding: "5px",
              }}
            >
              <Grid container>
                <Grid item container justifyContent="center">
                  <Grid item m={1}>
                    <TextField
                      type="search"
                      sx={{ backgroundColor: "#fff" }}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      autoFocus
                      size="small"
                      label="Search"
                    ></TextField>
                  </Grid>
                  <Grid item m={1}>
                    <Button variant="outlined" onClick={handleSearchClick}>
                      Go
                    </Button>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Box sx={{ width: "100%" }}>
                    <Card sx={{ backgroundColor: "#f6f6f6" }}>
                      <CardContent>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell>Company</TableCell>
                              <TableCell>Email</TableCell>
                              <TableCell>Address 1</TableCell>
                              <TableCell>Orders</TableCell>
                              <TableCell>Last Activity</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {customers.items.map((c, i) => (
                              <TableRow key={i}>
                                <TableCell>
                                  <Button
                                    size="small"
                                    onClick={() => handleSelectAccountClick(c)}
                                  >
                                    Select
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <OrdersDialogButton customerId={c.id}></OrdersDialogButton>
                                </TableCell>
                                <TableCell>{c.company}</TableCell>
                                <TableCell>{c.email}</TableCell>
                                <TableCell>{c.address1}</TableCell>
                                <TableCell>{c.orders}</TableCell>
                                <TableCell>
                                  {format(
                                    Date.parse(c.lastActivity),
                                    "dd/MM/yyyy hh:mm"
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <Pagination
                          count={customers.pageCount}
                          onChange={handlePageChange}
                        ></Pagination>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </DialogContent>
      </Dialog>
    </div>
  );
}
