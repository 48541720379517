import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { callApiAsync } from "../../utils/apiUtil";
import { useMsal } from "@azure/msal-react";

const columns = [
  { field: "itemTitle", headerName: "Item Title", width: 450 },
  { field: "description", headerName: "Description", width: 450 },
  { field: "plu", headerName: "PLU", width: 400 },
  { field: "bulkLocations", headerName: "Bulk Locations", width: 400 },
];

export default function OverBulkLimit() {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { instance } = useMsal();

  useEffect(() => {
    setIsLoading(true);
    callApiAsync(instance, null, "GET", "/api/Bulk/overlimit")
      .then((response) => response.json())
      .then((data) => {
        setRows([...data]);
        setIsLoading(false);
      });
  }, [instance]);

  return (
    <div style={{ height: 750, width: "95%", margin: "50px" }}>
      <DataGrid columns={columns} rows={rows} loading={isLoading} />
      <Button variant="contained" size="large" onClick={() => navigate("/")}>
        Back
      </Button>
    </div>
  );
}
