import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  useOrder,
  useOrderDispatch,
} from "../../../context/Order/OrderContext";
import { useSnackbarMessage } from "../../../context/SnackbarMessageContext";
import { addItem, setQtyField } from "../../../context/Order/orderActions";

export default function ProductCard(props) {
  const { triggerMessage } = useSnackbarMessage();
  const dispatch = useOrderDispatch();
  const order = useOrder();

  const handleCardClick = () => {
    addItem({ qty: order.qtyField, ...props }, order.refundMode, dispatch);
    triggerMessage("PRODUCT ADDED TO ITEMS LIST", "success");
    setQtyField(1, dispatch);
  };
  console.log(props);
  const stockLevel =
    props.stockLevel === 0 ? (
      <Typography
        sx={{ fontSize: "12px" }}
        align="center"
        variant="body1"
        color="red"
      >
        Out Of Stock
      </Typography>
    ) : (
      <Typography sx={{ fontSize: "12px" }} align="center" variant="body1">
        Items in Stock:{" "}
        <Box color="red" component="span">
          {props.stockLevel}
        </Box>
      </Typography>
    );

  return (
    <div>
      <Card sx={{ cursor: "pointer" }} onClick={() => handleCardClick()}>
        <CardMedia
          sx={{ height: 100, backgroundSize: '100px 100px' }}
          image={props.pictureUrl}
          title={props.name}
        ></CardMedia>
        <CardContent>
          <Typography align="center" variant="body1">
            {props.sku}
          </Typography>

          <Typography
            sx={{ fontSize: "12px" }}
            align="center"
            variant="subtitle2"
          >
            {props.name}
          </Typography>
          <Typography color="red" align="center" variant="body1">
            £{props.price.toFixed(2)}
          </Typography>
          {stockLevel}
          {props.tierPrices?.length > 0 ? (
            <Table size="small" sx={{ maxWidth: "100px" }} align="center">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: "12px" }}>Quantity</TableCell>
                  <TableCell sx={{ fontSize: "12px" }}>Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.tierPrices.map((t) => (
                  <TableRow>
                    <TableCell sx={{ fontSize: "12px" }}>
                      {t.quantity}
                    </TableCell>
                    <TableCell sx={{ fontSize: "12px" }}>
                      £{t.price.toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <></>
          )}
          <Table size="small" sx={{ maxWidth: "100px" }} align="center">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: "12px" }}>Shelf</TableCell>
                <TableCell sx={{ fontSize: "12px" }}>Bulk</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                  <TableCell sx={{ fontSize: "12px" }}>
                    {props?.shelfLocation}
                  </TableCell>
                  <TableCell sx={{ fontSize: "12px" }}>
                    {props?.bulkLocations}
                  </TableCell>
                </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
}
