import { useState } from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom"


function LocationInput(props) {
  const navigate = useNavigate();
  const [shelfLocation, setShelfLocation] = useState(true);
  const [locationInput, setlocationInput] = useState("");

  var onChangeShelf = (input) => {
    document.querySelector(".input").value = locationInput;
  }

  var onKeyPress = (button) => {
    if (button == '{bksp}') setlocationInput(locationInput.substring(0, locationInput.length - 1));
    else if (button == 'clear')setlocationInput('');
    else setlocationInput(locationInput + button);
  }
  var onChangeBulk = (input) => {
    document.querySelector(".bulk-input").value = locationInput;
  }
  const handleRadioChange = (e) => {
    setlocationInput("");
    if (e.target.value == "shelf") setShelfLocation(true);
    else setShelfLocation(false);
  }

  const handleBulkLocationPress = () => {
    navigate(`/bulkproducts/${locationInput}`);
  }

  const handleShelfLocationPress = () => {
    navigate(`/shelfproducts/${locationInput}`);
  }
  const handleOverBulkLimitPress = () => {
    navigate('/overbulklimit');
  }

  return (

    <div>
      <div style={{textAlign: 'center', margin:"20px"}}>
      <label style={{fontSize:"32px", color: "#1976D2", fontWeight:"500"}}>Enter Location Number And Select Load Location</label>
      </div>
      <div style={{margin:'auto', textAlign: 'center'}}>
        
      <Button style={{margin: "auto"}} variant="contained" size="large" onClick={handleOverBulkLimitPress}>Over Bulk Limit</Button>
      </div>
      <div className='location-div'>      
        <label style={{fontSize: '24px', color: "#1976D2", fontWeight:"500"}}>Shelf <input type="radio" value="shelf" onClick={handleRadioChange} name='locationScreen' checked={shelfLocation === true} /></label>
        <label style={{fontSize: '24px', color: "#1976D2", fontWeight:"500"}}>Bulk <input type="radio" name='locationScreen' value="bulk" onClick={handleRadioChange} checked={shelfLocation === false} /></label>
      </div>

      {shelfLocation === true ?
        <div className='keyboard-div'>
          <Keyboard
            onChange={onChangeShelf}
            onKeyPress={onKeyPress}
            theme="keyboardTheme"
            layout={{
              default: [
                "A B C D E F G H I J K L M",
                'N O P Q R S T U V W X Y Z',
                "0 1 2 3 4 5 6 7 8 9 {bksp}"
              ]
            }}
          />
          <input className="input" type="text" value={locationInput} />
          <Button style={{
            height: "50px",
            display: "block",
            textAlign: "center",
            margin: "auto",
            marginTop: "20px"
          }} variant="contained" size="large" onClick={handleShelfLocationPress}>Load Location</Button>
        </div>

        :

        <div className='bulk-keyboard-div'>
          <Keyboard onChange={onChangeBulk}
            onKeyPress={onKeyPress}
            theme="bulkKeyboardTheme"
            layout={{
              default: [
                "7 8 9",
                '4 5 6',
                "1 2 3",
                '0',
                'clear'
              ]
            }}
          />
          <div className='bulk-load-div'>
            <input className="bulk-input" type="text" value={locationInput} />
            <Button style={{ marginTop: "20px" }} variant="contained" size="large" onClick={handleBulkLocationPress} >
              Load Location
            </Button>
          </div>
        </div>
      }


    </div>

  );
}
export default LocationInput