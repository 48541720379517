import {
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  Skeleton,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { callApiAsync } from "../../../utils/apiUtil";
import { useMsal } from "@azure/msal-react";
import ProductCard from "../BrowseTab/ProductCard";
import { RestartAlt } from "@mui/icons-material";

export default function SearchTabPanel(props) {
  const { children, value, index, ...other } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [showUnpublished, setShowUnpublished] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const { instance } = useMsal();

  const handleGoOnCick = async () => {
    let data = {
      value: searchTerm,
      showUnpublished : showUnpublished
    };
    setSearchResults(null);
    setLoading(true);
    let response = await callApiAsync(
      instance,
      data,
      "POST",
      "/api/product/search"
    );
    if (response.ok) {
      setSearchResults(await response.json());
    }
    setLoading(false);
  };

  const handleGoOnKeyDown = async (event) => {
    if (event.key === "Enter") {
      await handleGoOnCick();
    }
  };

  const handleResetOnClick = (event) => {
    setSearchResults(null);
    setLoading(false);
    setSearchTerm("");
  };

  const loadingProductItems = isLoading ? (
    <Grid container alignItems="center" justifyContent="center">
      {[1, 2, 3, 4, 5, 6].map((i) => (
        <Grid key={i} item xs={3} m={2}>
          <Skeleton variant="rectangular" width={200} height={100} />
          <Box sx={{ pt: 2 }}>
            <Skeleton width="100%" />
            <Skeleton width="80%" />
            <Skeleton width="50%" />
            <Skeleton width="90%" />
            <Skeleton width="30%" />
          </Box>
        </Grid>
      ))}
    </Grid>
  ) : (
    <></>
  );
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ height: "650px", overflow: "auto" }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    margin="normal"
                    checked={showUnpublished}
                    onChange={(e) => setShowUnpublished(e.target.checked)}
                  ></Checkbox>
                }
                label="Show Unpublished"
              />
            </Grid>
            <Grid item>
              <TextField
                sx={{ backgroundColor: "#fff" }}
                margin="normal"
                label="Search"
                value={searchTerm}
                type="search"
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleGoOnKeyDown}
              ></TextField>
            </Grid>
            <Grid item>
              <Button onClick={handleGoOnCick} variant="contained">
                Go
              </Button>
            </Grid>
            <Grid item>
              <IconButton onClick={handleResetOnClick} variant="contained">
                <RestartAlt />
              </IconButton>
            </Grid>
          </Grid>
          {loadingProductItems}
          <Grid container alignItems="center" justifyContent="center">
            {searchResults ? (
              <Grid container alignItems="center" justifyContent="center">
                <Grid item>
                  <Typography variant="caption">
                    Result Count: {searchResults.length}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  alignItems="center"
                  justifyContent="center"
                >
                  {searchResults.map((element) => {
                    return (
                      <Grid key={element.id} item xs={2} m={1}>
                        <ProductCard {...element}></ProductCard>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Box>
      )}
    </div>
  );
}

SearchTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
