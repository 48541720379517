import { createContext, useContext, useReducer } from "react";

export const UIContext = createContext(null);
export const UIDispatchContext = createContext(null);

export function UIProvider({ children }) {
  const [ui, dispatch] = useReducer(uiReducer, { focus: true });

  return (
    <UIContext.Provider value={ui}>
      <UIDispatchContext.Provider value={dispatch}>
        {children}
      </UIDispatchContext.Provider>
    </UIContext.Provider>
  );

  function uiReducer(ui, action) {
    switch (action.type) {
      case uiActions.SET_FOCUS: {
        return { ...ui, focus: !ui.focus };
      }

      case uiActions.SET_CASH_DRAWER: {
        return { ...ui, cashDrawer: action.device };
      }

      case uiActions.DELETE_CASH_DRAWER: {
        return { ...ui, cashDrawer: null };
      }

      default: {
        throw Error("Unknown action: " + action.type);
      }
    }
  }
}

var uiActions = {
  SET_FOCUS: "set-focus",
  SET_CASH_DRAWER: "set-cash-drawer",
  DELETE_CASH_DRAWER: "delete-cash-drawer",
};

export function setFocus(dispatch) {
  dispatch({
    type: uiActions.SET_FOCUS,
  });
}

export function setCashDrawer(device, dispatch) {
  dispatch({
    type: uiActions.SET_CASH_DRAWER,
    device,
  });
}

export function deleteCashDrawer(dispatch) {
  dispatch({
    type: uiActions.DELETE_CASH_DRAWER,
  });
}

export function useUI() {
  return useContext(UIContext);
}

export function useUiDispatch() {
  return useContext(UIDispatchContext);
}
