import { useState, useEffect } from "react";
import { useParams } from "react-router";
import ProductTable from "./ProductTable";
import Button from "@mui/material/Button";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Keyboard from "react-simple-keyboard";
import { useMsal } from "@azure/msal-react";
import { callApiAsync } from "../../utils/apiUtil";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

const BulkProducts = () => {
  const navigate = useNavigate();
  const { bulkId } = useParams();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pluInput, setPluInput] = useState("");
  const pluInputRef = useRef(null);
  const [multipleChoice, setMultipleChoice] = useState([]);
  const [showNumPad, setShowNumPad] = useState(false);
  const { instance } = useMsal();

  useEffect(() => {
    console.log("reloaded");
    setIsLoading(true);
    callApiAsync(instance, null, "GET", `/api/Bulk/${bulkId}`)
      .then((response) => response.json())
      .then((data) => {
        setRows([...data]);
        setIsLoading(false);
      });

    pluInputRef.current.focus();
  }, [bulkId, instance]);

  const handleDeleteRow = (rowIds) => {
    let newRows = rows;
    confirmAlert({
      title: "Confirm to delete",
      message: `Are you sure you want to delete the selected rows?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setIsLoading(true);
            rowIds.forEach(async (id) => {
              await callApiAsync(instance, null, "DELETE", `/api/bulk/${bulkId}/${id}`);
              newRows = newRows.filter((item) => item.id !== id);
              console.log(newRows);
              setRows(newRows);
            });
            setIsLoading(false);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const nextBulk = () => {
    navigate(`/bulkproducts/${Number(bulkId) + 1}`);
  };

  const prevBulk = () => {
    navigate(`/bulkproducts/${Number(bulkId) - 1}`);
  };

  const handleAddButton = () => {
    var product;
    callApiAsync(instance, null, "GET", `/api/Bulk/add/${pluInput}`)
      .then((response) => response.json())
      .then((data) => {
        product = data[0];

        if (data.length === 1) {
          callApiAsync(
            instance,
            null,
            "POST",
            `/api/Bulk/add/${bulkId}/${product.id}`
          )
            .then((response) => response.json())
            .then((data) => setRows([...rows, data]));
        } else {
          setMultipleChoice(data);
        }
      });
    setPluInput("");
    pluInputRef.current.focus();
    setShowNumPad(false);
  };

  const handleMultipleSelection = (product) => {
    callApiAsync(
      instance,
      null,
      "POST",
      `/api/Bulk/add/${bulkId}/${product.id}`
    )
      .then(setMultipleChoice([]))
      .then((response) => response.json())
      .then((data) => setRows([...rows, data]));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddButton();
    }
  };

  var onChangeBulk = () => {
    document.querySelector(".plu-input").value = pluInput;
    console.log(pluInput);
  };

  var onKeyPress = (button) => {
    if (button === "{bksp}")
      setPluInput(pluInput.substring(0, pluInput.length - 1));
    else if (button === "clear") setPluInput("");
    else setPluInput(pluInput + button);
  };

  return (
    <div style={{ margin: "15px" }}>
      <label
        style={{
          fontSize: "32px",
          color: "#1976D2",
          fontWeight: "500",
          margin: "10px",
        }}
      >
        Bulk Location - {bulkId}
      </label>
      <label style={{ marginRight: "10px", width: "150px" }}>
        <input
          style={{ width: "25px", marginLeft: "20%" }}
          type="text"
          disabled
        />{" "}
        Product Live
      </label>
      <label>
        <input
          style={{ backgroundColor: "#FFE0B2", width: "25px" }}
          type="text"
          disabled
        />{" "}
        Product Not Live
      </label>
      <div>
        <label style={{ margin: "10px", width: "100%" }}>
          PLU{" "}
          <input
            style={{ width: "75%", marginRight: "20px" }}
            className="plu-input"
            type="text"
            ref={pluInputRef}
            value={pluInput}
            onKeyDown={handleKeyDown}
            onChange={(e) => setPluInput(e.target.value)}
          />
          <Button
            style={{ margin: "10px" }}
            variant="contained"
            size="small"
            onClick={handleAddButton}
          >
            Add
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => setShowNumPad(!showNumPad)}
          >
            {showNumPad ? "Hide" : "NumPad"}
          </Button>
        </label>
      </div>
      <div>
        {showNumPad && (
          <div className="bulk-keyboard-div">
            <Keyboard
              onChange={onChangeBulk}
              onKeyPress={onKeyPress}
              theme="numPadTheme"
              layout={{
                default: ["7 8 9", "4 5 6", "1 2 3", "0", "clear"],
              }}
            />
          </div>
        )}
        {multipleChoice.length > 1 && (
          <div>
            <ul style={{ listStyleType: "none", marginBottom: "50px" }}>
              {multipleChoice.map((product, key) => (
                <li style={{ height: "50px", margin: "50px" }} key={key}>
                  <Button
                    style={{ margin: "20px" }}
                    variant="contained"
                    size="large"
                    onClick={() => handleMultipleSelection(product)}
                  >
                    {product.name}
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        )}
        {isLoading ? <h4>Loading...</h4> : <></>}
        <ProductTable
          loading={isLoading}
          tableRows={rows}
          handleDelete={handleDeleteRow}
          dataType={true}
          nextRow={nextBulk}
          previousRow={prevBulk}
        />
      </div>
    </div>
  );
};
export default BulkProducts;
