import { protectedResources } from "../authConfig";

export async function callApiAsync(msalInstance, data, method, url) {
  let account = msalInstance.getActiveAccount();
  let token = await msalInstance.acquireTokenSilent({
    scopes: protectedResources.api.scopes.read,
    account: account,
  });
  const headers = new Headers();
  const bearer = `Bearer ${token.accessToken}`;
  headers.append("Authorization", bearer);

  if (data) {
    headers.append("Content-Type", "application/json");
    let json = JSON.stringify(data);
    return await fetch(url, {
      method: method,
      headers: headers,
      body: json,
    });
  } else {
    return await fetch(url, {
      method: method,
      headers: headers,
    });
  }
}
