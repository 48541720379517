/**
 * @typedef {Object} OrderDto
 * @property {number} customerId
 * @property {string} customerName
 * @property {number} id
 * @property {string} orderDateUtc
 * @property {number} orderDiscount
 * @property {string} orderNumber
 * @property {string} orderStatus
 * @property {number} orderSubtotal
 * @property {number} orderTotal
 * @property {string} paidDateUtc
 * @property {number} taxAmount
 * @property {number} taxRate
 * @property {OrderItemDto[]} orderItems
 *
 *
 */

/**
 * @typedef {Object} OrderItemDto
 *
 */

import { formatISO } from "date-fns";
import { callApiAsync } from "../utils/apiUtil";
import { discountTypes } from "../constants/discountTypes";
import printJS from "print-js";

export const generateInvoicePdf = async (order, instance) => {
  return await generatePdfApiCallAsync("invoice", order, instance);
};

export const printReceipt = async (order, instance) => {
  const response = await callApiAsync(
    instance,
    order,
    "POST",
    `/api/pdf/invoice`
  );
  const file = await response.blob();
  const fileURL = URL.createObjectURL(file);
  printJS(fileURL);
};

export const generateDeliveryNotePdf = async (order, instance) => {
  return await generatePdfApiCallAsync("deliverynote", order, instance);
};

export const generatePickingSheetPdf = async (order, instance) => {
  return await generatePdfApiCallAsync("pickingsheet", order, instance);
};

export const generateCreditNotePdf = async (order, instance) => {
  return await generatePdfApiCallAsync("creditnote", order, instance);
};

export const generateOrderConfirmationPdf = async (order, instance) => {
  return await generatePdfApiCallAsync("orderconfirmation", order, instance);
};

export const generatePdfOrderData = (order) => {
  return ConvertOrderToModel(order);
};

export const generatePdfApiCallAsync = async (type, orderData, instance) => {
  const response = await callApiAsync(
    instance,
    orderData,
    "POST",
    `/api/pdf/${type}`
  );
  const file = await response.blob();
  const fileURL = URL.createObjectURL(file);
  const pdfWindow = window.open();
  pdfWindow.location.href = fileURL;
};

export const completeOrder = async (order, instance) => {
  let orderData = ConvertOrderToModel(order);
  return await callApiAsync(instance, orderData, "POST", "/api/order");
};

export const getCustomers = async (page, searchModel, instance) => {
  let { searchTerm } = searchModel;
  let query = `page=${page}`;
  if (searchTerm) query += `&searchTerm=${encodeURIComponent(searchTerm)}`;

  return await callApiAsync(instance, null, "GET", `/api/customer?${query}`);
};

export const getOrders = async (page, searchModel, instance) => {
  let { dateFrom, dateTo, searchTerm, customerId, layawayOrders } = searchModel;
  page ??= 1;
  let query = `page=${page}`;
  if (dateFrom) query += `&dateFrom=${encodeURIComponent(formatISO(dateFrom))}`;
  if (dateTo) query += `&dateTo=${encodeURIComponent(formatISO(dateTo))}`;
  if (searchTerm) query += `&searchTerm=${encodeURIComponent(searchTerm)}`;
  if (customerId) query += `&customerId=${encodeURIComponent(customerId)}`;
  if (layawayOrders)
    query += `&layawayOrders=${encodeURIComponent(layawayOrders)}`;
  return await callApiAsync(instance, null, "GET", `/api/order?${query}`);
};

export const saveOrderChanges = async (order, instance) => {
  let orderData = ConvertOrderToModel(order);
  return await callApiAsync(instance, orderData, "PUT", "/api/order");
};

export const ConvertOrderToModel = (order) => {
  return {
    id: order.id,
    orderNumber: order.orderNumber,
    customerId: order.account?.id ?? null,
    orderDiscount: order.orderDiscount,
    orderSubtotal: order.netTotal,
    orderTotal: order.total,
    taxRate: order.vatPercentage,
    taxAmount: order.vatAmount,
    refundAmount: order.refundAmount,
    account: order.account,
    payments: order.payments.map((s) => {
      return { ...s, paymentDate: formatISO(s.paymentDate) };
    }),
    orderItems: order.items.map((i) => {
      return ConvertItemToModel(i);
    }),
  };
};

export const ConvertItemToModel = (i) => {
  return {
    id: i.id,
    name: i.description,
    productId: i.productId,
    qty: i.qty,
    isRefund: i.isRefund,
    refundReason: i.refundReason,
    unitPrice: i.unitPrice,
    netPrice: i.netPrice,
    price: i.price,
    discountAmount: i.discountAmount,
    assortedBox: i.assortedBox,
  };
};

export const ConvertModelToItem = (i) => {
  return {
    ...i,
    discount: i.discountAmount ?? 0,
    discountType: discountTypes.pounds,
    netPrice: i.netPrice,
    unitPrice: i.unitPrice,
    productUnitPrice: i.productUnitPrice,
    description: i.name,
    isRefund: i.isRefund,
    refundReason: i.refundReason,
    assortedBox: i.assortedBox,
  };
};

export const ConvertModelToOrder = (orderModel) => {
  return {
    id: orderModel.id,
    orderNumber: orderModel.orderNumber,
    netTotal: orderModel.orderSubTotal,
    vatAmount: orderModel.taxAmount,
    vatPercentage: orderModel.taxRate,
    orderDiscount: orderModel.orderDiscount ?? 0,
    total: orderModel.orderTotal,
    refundAmount: orderModel.refundAmount,
    orderDiscountType: discountTypes.pounds,
    account: orderModel.account,
    payments: orderModel.payments.map((p) => {
      return {
        id: p.id,
        paymentAmount: p.paymentAmount,
        paymentType: p.paymentType,
        paymentDate: Date.parse(p.paymentDate),
      };
    }),
    items: orderModel.orderItems.map((i) => {
      return ConvertModelToItem(i);
    }),
  };
};

export const CreateOrderIfNotExists = async (order, instance) => {
  return await callApiAsync(instance, order, "POST", "/api/order");
};

export const ChangeOrder = async (order, instance) => {
  return await callApiAsync(instance, { ...order }, "PUT", `/api/order`);
};

export const AddItemToOrder = async (item, orderId, instance) => {
  return await callApiAsync(
    instance,
    item,
    "POST",
    `/api/order/items/${orderId}`
  );
};

export const CompleteOrder = async (orderId, instance) => {
  return await callApiAsync(
    instance,
    { orderId },
    "PUT",
    `/api/order/${orderId}`
  );
};
